// Packages:
import React, { memo, useEffect, useState } from "react";
import {
    Autocomplete,
  FormControl,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
// Styles:
import { FieldName } from "../styles";
import useInput from "../../../../lib/hooks/use-input";
import { ACTION, CHANNEL, DEFAULT_OUTPUT_SOURCEID, DEFAULT_OUTPUT_USERID, LOGISTICS_RESPONSE_FIELDS } from "./utils";
import AutocompleteGlobal from "../../Components/AutocompleteGlobal";
import BaseConfiguration from "../BaseConfiguration";

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;
const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  max-height: 36px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const DEFAULT_OUTPUT = {
      key: "shipments",
      value: {
        fieldName: "order_details.shipping_details",
        value: null,
      },
    }

// Functions:
const LogisticsConfiguration = ({
  node,
  updateNode,
  deleteNode,
  isEditable,
  currentRemoteWorkflow,
  setNodes
}) => {
  const [inputData, setInputData] = useState(() => {
    const input = currentRemoteWorkflow?.meta?.settings?.input || [];
    return input.map((val) => {
      return `${val.variableName}`;
    });
  });

  const [userId,setUserId]=useState(node.userId??DEFAULT_OUTPUT_USERID)
  const [sourceId,setSourceId]=useState(node.sourceId??DEFAULT_OUTPUT_SOURCEID)
  const [channel, setChannel] = useState(node.channel ?? "USPS");
  const [action, setAction] = useState(node.action ?? "TRACK ORDER");
  const [shipments, setShipmets] = useState(node.shipments ?? DEFAULT_OUTPUT);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields,setOutputFields] = useState([])

  useEffect(() => {
    setUserId(node.userId??DEFAULT_OUTPUT_USERID);
    setChannel(node.channel ?? "USPS");
    setAction(node.action ?? "TRACK ORDER");
    setShipmets(node.shipments??DEFAULT_OUTPUT);
    setSourceId(node.sourceId??DEFAULT_OUTPUT_SOURCEID);
  }, [node.userId, node.channel, node.action,node.shipments,node.sourceId]);

  useEffect(() => {
    updateNode(node.id, {
      userId,
      channel,
      action,
      shipments,
      sourceId,
      dynamicFields: [...LOGISTICS_RESPONSE_FIELDS,...dynamicFields],
      outputFields: [...outputFields, {id: node?.id,values: [...LOGISTICS_RESPONSE_FIELDS]}], 
}
    );
  }, [userId, channel, action,shipments,sourceId,dynamicFields]);


  // Return:
  return (
    <BaseConfiguration setDynamicFields={setDynamicFields} node={node} isEditable={isEditable} deleteNode={deleteNode} outputFields={outputFields} setOutputFields={setOutputFields} setNodes={setNodes}>
      <FieldName>Channel</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="channel"
          disabled={!isEditable}
          value={channel}
          onChange={(e) => setChannel(e.target.value)}
        >
          {Object.values(CHANNEL).map((channel, index) => (
            <StyledMenuItem key={index} value={channel}>
              {channel}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <FieldName>Action</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="ticket-action"
          disabled={!isEditable}
          value={action}
          onChange={(e) => setAction(e.target.value)}
        >
          {Object.values(ACTION).map((action, index) => (
            <StyledMenuItem key={index} value={action}>
              {action}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>

      <AutocompleteGlobal
           Title="User Id"
           dynamicFields={inputData}
           data={userId} 
           setData={setUserId}
           DEFAULT_OUTPUT={DEFAULT_OUTPUT_USERID}
           isEditable={isEditable}
      />
      <AutocompleteGlobal
           Title="Source Id"
           dynamicFields={inputData}
           data={sourceId} 
           setData={setSourceId}
           DEFAULT_OUTPUT={DEFAULT_OUTPUT_SOURCEID}
           isEditable={isEditable}
      />
      <AutocompleteGlobal Title={"Shipments"} dynamicFields={dynamicFields} data={shipments} setData={setShipmets} DEFAULT_OUTPUT={DEFAULT_OUTPUT} isEditable={isEditable} />
    </BaseConfiguration>
  );
};

// Exports:
export default memo(LogisticsConfiguration);