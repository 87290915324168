// Packages:
import React, { memo, useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Tooltip,
  Button,
  FormControlLabel,
  Checkbox,
  styled,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 1rem;
    width: 50%;
  }

  & .MuiTypography-root {
    font-family: "Inter", sans-serif;
  }

  & .MuiDialogActions-root {
    padding: 20px 24px;
    display: flex;
    margin: 0 auto;
  }
`;

const StyledContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

// Functions:
const IntervalBox = ({
  settingsOpen,
  handleSettingsClose,
  settings,
  setCurrentRemoteWorkflow,
  isEditable,
}) => {
  // State:
  const [frequencyErrorText, setFrequencyErrorText] = useState("");
  const [unitErrorText, setUnitErrorText] = useState("");
  const [frequency, setFrequency] = useState(
    settings?.schedule_setting?.frequency ?? ""
  );
  const [unit, setUnit] = useState(settings?.schedule_setting?.unit ?? "days");

  const [inputExpected, setInputExpected] = useState(settings?.inputExpected);
  const [numberOfInput, setNumberOfInput] = useState(settings?.numberOfInput??0);
  const [globalData, setGlobalData] = useState(settings?.input??
    Array.from({ length: numberOfInput }, () => ({
      variableName: "",
      value: "",
    })
  ));

  const [data, setData] = useState({
    schedule_workflow: settings?.schedule_workflow,
    schedule_setting: {
      frequency: frequency,
      unit: unit,
    },
    verification_url: settings?.verification_url,
  });

  const handleInputChange = (event) => {
    const newFrequency = event.target.value;
    setFrequency(newFrequency);
    setData((prevData) => ({
      ...prevData,
      schedule_setting: {
        ...prevData.schedule_setting,
        frequency: newFrequency,
      },
    }));
  };

  const handleUnitChange = (event) => {
    const newUnit = event.target.value;
    setUnit(newUnit);
    setData((prevData) => ({
      ...prevData,
      schedule_setting: {
        ...prevData.schedule_setting,
        unit: newUnit,
      },
    }));
  };

  // Event Handler:
  const handleSaveSettings = () => {
    setCurrentRemoteWorkflow((prev) => ({
      ...prev,
      meta: {
        ...prev.meta,
        settings: {
          ...data,
          inputExpected,
          numberOfInput,
          input:[
            ...globalData,
          ],
          schedule_setting: data.schedule_workflow ? data.schedule_setting : {},
        },
      },
    }));
    handleSettingsClose();
  };

  useEffect(() => {
    if (data.schedule_workflow && (!frequency || !unit)) {
      setFrequencyErrorText("Frequency cannot be empty");
      setUnitErrorText("Unit fields cannot be empty");
    } else {
      if (frequency <= 0)
        setFrequencyErrorText("Frequency must be greater than 0");
      else setFrequencyErrorText("");
      if (unit !== "") setUnitErrorText("");
    }
  }, [data, frequency, unit]);

  const handleCheckboxChange =useCallback(() => {
    setInputExpected(!inputExpected);
    setNumberOfInput(0);
    setGlobalData([]);
  },[inputExpected]);

  const handleNumberOfInputChange = (e) => {
    const value = parseInt(e.target.value, 10) || 0;
    setNumberOfInput(value);
  
    setGlobalData((prevGlobalData) => {
      const newGlobalData = [...prevGlobalData];
  
      if (value < newGlobalData.length) {
        newGlobalData.length = value;
      } else if (value > newGlobalData.length) {
        for (let i = newGlobalData.length + 1; i <= value; i++) {
          newGlobalData.push({ variableName: "", value: "" });
        }
      }
        return newGlobalData;
    });
  };

  const handleDataInputChange = (index, key, value) => {
    const newData = [...globalData];
    newData[index][key] = value;
    setGlobalData(newData);
  };

  // Return:
  return (
    <StyledDialog open={settingsOpen} onClose={handleSettingsClose}>
      <DialogTitle sx={{ mb: "1rem", fontSize: "1.25rem", color: "#333333" }}>
        Settings
        <Tooltip title="close">
          <IconButton
            onClick={handleSettingsClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <div style={{display:'flex',flexDirection:'row',marginTop:'1rem',marginBottom:'1rem',alignItems:'center',justifyContent:'space-between'}}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={!isEditable}
              checked={inputExpected}
              onChange={handleCheckboxChange}
            />
          }
          label="Input Expected"
        />
         {inputExpected && (
          <TextField
                  type="number"
                  placeholder="0"
                  label="Number of input"
                  disabled={!isEditable}
                  value={numberOfInput}
                  inputProps={{min:0}}
                  style={{ width: "50%" }}
                  onChange={handleNumberOfInputChange}
                  size="small"
                />)}
        </div>

      {inputExpected && (
        <div style={{marginBottom:'2rem'}}>
          {globalData.map((_, index) => (
            <div key={index}>
              <TextField
                  type="text"
                  placeholder="Variable Name"
                  disabled={!isEditable}
                  value={globalData[index].variableName}
                  style={{ width: "40%" }}
                  size="small"
                  onChange={(e) =>
                    handleDataInputChange(index, "variableName", e.target.value)
                  }
                />
               <TextField
                  type="text"
                  placeholder="Value"
                  disabled={!isEditable}
                  value={globalData[index].value}
                  style={{ width: "60%" }}
                  size="small"
                  onChange={(e) =>
                    handleDataInputChange(index, "value", e.target.value)
                  }
                />
            </div>
          ))}
        </div>
      )}
        <TextField
          size="small"
          disabled={!isEditable}
          placeholder="Enter verification URL"
          value={data.verification_url}
          onChange={(e) =>
            setData({ ...data, verification_url: e.target.value })
          }
          fullWidth
          sx={{ marginBottom: "1rem" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={!isEditable}
              checked={data.schedule_workflow}
              onChange={(e) =>
                setData({ ...data, schedule_workflow: e.target.checked })
              }
            />
          }
          label="Periodic"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          {data.schedule_workflow && (
            <>
              <StyledContainer>
                <TextField
                  type="number"
                  placeholder="0"
                  label="frequency"
                  disabled={!isEditable}
                  value={frequency}
                  style={{ width: "50%" }}
                  onChange={handleInputChange}
                  error={!!frequencyErrorText}
                  helperText={frequencyErrorText}
                />
                <FormControl style={{ width: "80%" }} disabled={!isEditable}>
                  <Select
                    value={unit}
                    onChange={handleUnitChange}
                    disabled={!isEditable}
                    error={!!unitErrorText}
                  >
                    <MenuItem value="days">Days</MenuItem>
                    <MenuItem value="hours">Hours</MenuItem>
                    <MenuItem value="minutes">Minutes</MenuItem>
                  </Select>
                  {unitErrorText && (
                    <FormHelperText error>{unitErrorText}</FormHelperText>
                  )}
                </FormControl>
              </StyledContainer>
            </>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          size="medium"
          disabled={((!isEditable )||(data.schedule_workflow&&(frequencyErrorText || unitErrorText)))}
          startIcon={<SaveOutlinedIcon />}
          sx={{ textTransform: "none" }}
          color="success"
          onClick={() => handleSaveSettings()}
        >
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

// Exports:
export default  memo(IntervalBox);
