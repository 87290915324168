// Packages:
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useGlobalFilter
} from 'react-table';
import { CSVLink } from 'react-csv';
import { Chip, CircularProgress, Button } from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import { TableSortLabel } from '@mui/material';

// Components:
// import Pagination from '../Pagination'
import Pagination from '../../../components/Records/Pagination';

// Imports:
import { getChatActions } from '../../../redux/actions/chatActions';
import { ColumnFilter } from './ColumnFilter';
import {
  Wrapper,
  RecordsTable,
  Header,
  Body,
  Title,
  Filters
} from './styles';
import { SESSION_TOOLTIPS } from '../../../constants/tooltips';
import GlobalFilter from '../GlobalFilter';
import { extractCoreText } from '../utils'
import { REQUEST_SESSION_TYPE } from '../../../constants/requestType';
import sessionConfig from '../../../config/infov2/session_table.json';
import { filteredChatData, globalFilterChatData } from '../../../utils/tableFilters';
import { sortingDateFunction } from '../../../utils/tableSort';
import { selectSessionItems } from '../../../redux/selectors/chatSelectors';

const { headerMap } = sessionConfig;

const currPage = 0;
const varPageSize = 5;

function setTopicFilters(filters){
  let newTopicFiltes = {
    sentiment: [],
    outcome: []
  };
  for(const filter of filters){
    const {id, value} = filter;
    if(["Sentiment", "Outcome"].includes(id)){
      newTopicFiltes[id.toLowerCase()] = value;
    }
  }
  sessionStorage.setItem('topicFilters', JSON.stringify(newTopicFiltes));
}
let initialState = {
  hiddenColumns: ['Record'],
  sortBy: [
    {
      id: 'Created',
      desc: true
    }
  ],
  pageSize: varPageSize,
  pageIndex: currPage,
};


// Functions:
const Table = (props) => {

  // Constants:
  const topicFilters = {
    sentiment: [],
    outcome: [],
    ...JSON.parse(sessionStorage.getItem('topicFilters') || '{"sentiment":[],"outcome": []}')
  };
  const dispatch = useDispatch();
  const chatActions = getChatActions(dispatch);
  const organisation_info = useSelector((state) => state.content.org_info);
  const org_id = organisation_info?.org_data?._id;
  const chatRecords = useSelector(selectSessionItems, shallowEqual);
  const lastKey = useSelector((state) => state.chat.chatRecordsLastKey);
  const isLoading = useSelector((state) => state.chat.loading);

  useEffect(() => {
    // if (chatRecords?.length && !lastKey) return;
    chatActions.fetchChatRecordsV3(org_id, props.startDate, props.endDate, REQUEST_SESSION_TYPE);
  }, [org_id]);

  let filter = [];
  if(topicFilters.sentiment.length){
    filter.push({id: 'Sentiment', value: topicFilters.sentiment});
  }
  if(topicFilters.outcome.length){
    filter.push({id: 'Outcome', value: topicFilters.outcome});
  }
  initialState = {...initialState, filters: filter};
  // State:
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState(chatRecords.slice(currPage * varPageSize, (currPage + 1) * varPageSize))
  const [pageCount, setPageCount] = useState(Math.ceil(chatRecords.length / varPageSize) || 1);
  const [cols, setCols] = useState([]);

  // useEffect(() => {
  //   if (chatRecords) {
  //     let filteredData = chatRecords; 
  //     if(props.conversationType.length > 0) {
  //       let conversationTypeFilteredData = filteredData.filter((item) => props.conversationType.includes(item.conversation_type))
  //       setData(conversationTypeFilteredData.slice(pageSize));
  //     }
  //     else {
  //       setData(filteredData.slice(pageSize));
  //     }
  //   }
  // }, [props.conversationType, chatRecords]);

  useEffect(() => {
    let records = chatRecords.slice(currPage * varPageSize, (currPage + 1) * varPageSize);
    let cols = ["user_email",
      "user_personas",
      "created_date",
      "outcome",
      "sentiment",
      "record",
      "record_id",
      "tags",
      "user_queries",
      "category"
    ];
    setData(records);
    setCols(cols);
  }, [chatRecords]);


  let columns = useMemo(() => {
    const tempArray = cols.map((col, i) => {
      const words = col.split('_');
      const capitalised = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );

      return {
        Header: col in headerMap ? headerMap[col] : capitalised.join(' '),
        accessor: row => {
          switch (col) {
            case 'created_date':
              return row?.created_date?.substring(0, 10) + " " + row?.created_date?.substring(11, 16);
            case 'tags':
              return typeof (row[col]) === "object" ? JSON.stringify(row[col]) : row[col];
            case 'category':
              return row?.analytics?.intent?.[0];
            case 'sentiment':
              return row?.analytics?.sentiment?.[0];
            case 'outcome':
              return row?.conversation_type;
            default:
              return row[col];
          }
        },
        Filter: ColumnFilter,
        disableSortBy: (col === "created_date") ? false : true,
        sortType: 'basic'
      };
    });
    return tempArray;
  }, [cols, props.hideInfo]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState,
      autoResetPage: false,
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      manualGlobalFilter: true,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
      pageCount: pageCount
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    setGlobalFilter,
    preGlobalFilteredRows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    prepareRow
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize, sortBy, filters } = state;

  const onPageChange = useCallback((newVal, records) => {
    let data = records.slice(newVal * pageSize, (newVal + 1) * pageSize);
    const pageCount = Math.ceil(records.length / pageSize);
    setPageCount(pageCount || 1);
    if (newVal > pageCount - 2 && lastKey && !isLoading) {
      chatActions.fetchChatRecordsV3(org_id, props.startDate, props.endDate, REQUEST_SESSION_TYPE);
    }
    return data;
  }, [pageIndex, lastKey, isLoading])

  useEffect(() => {
    if (chatRecords?.length) {
      let globalFilteredChat = globalFilterChatData(chatRecords, globalFilter);
      let filterChat = filteredChatData(globalFilteredChat, filters) || [];
      let sortedChatData = sortingDateFunction(filterChat, sortBy) || [];
      let record = onPageChange(pageIndex, sortedChatData) || [];
      setData(record);
    }
  }, [sortBy, filters, chatRecords, pageIndex, globalFilter])

  useEffect(() => {
    gotoPage(0);
    setTopicFilters(filters);
  }, [filters, globalFilter])

  const handleChatRecord = (row) => {
    setSelectedRow(row.id === selectedRow ? null : row.id);
    props.handleChat(row?.original);
  };


  return (
    <Wrapper>
      {
        !isLoading && <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          preGlobalFilteredRows={preGlobalFilteredRows}
        />
      }
      <RecordsTable {...getTableProps()}>
        {isLoading ?
          (<thead style={{ marginTop: '15rem' }}>
            <tr><th><CircularProgress /></th></tr>
          </thead>) :
          (<>
            <Header>
              {headerGroups.map((headerGroup) => (
                <Title {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (


                    <th {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}>
                      {column.disableSortBy ? (
                        column.render('Header')
                      ) : (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                          {...column.getSortByToggleProps()} title={SESSION_TOOLTIPS[column.Header]}
                        >
                          {column.render('Header')}
                        </TableSortLabel>
                      )}

                    </th>
                  ))}
                </Title>
              ))}
              {headerGroups.map((headerGroup) => (
                <Filters {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div>
                        {column.canFilter
                          ? column.render('Filter') : null
                        }
                      </div>
                    </th>
                  ))}
                </Filters>
              ))}
            </Header>
            {page && page.length > 0 ? (<>
              <Body {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row?.getRowProps()} onClick={() => handleChatRecord(row)} style={{ backgroundColor: selectedRow === row.id ? 'lightblue' : '' }}>
                      {row?.cells.map((cell) => {
                        if (cell.column.Header === 'User Type') {
                          return <td {...cell.getCellProps()}>
                            {(!!row?.original?.user_personas && row?.original?.user_personas?.length) ? row?.original?.user_personas.join(',') : 'N/A'}
                          </td>
                        }
                        else if (cell.column.Header === 'Outcome') {
                          return (
                            <td {...cell.getCellProps()}>
                              {
                                row?.original?.conversation_type === 'AI' ?
                                  <Chip label={row?.original?.conversation_type} icon={<SmartToyOutlinedIcon />} color="info" size="small" variant="outlined" sx={{ fontWeight: '600' }} /> :
                                  row?.original?.conversation_type === 'Leave Message' ?
                                    <Chip label={row?.original?.conversation_type} icon={<ExitToAppOutlinedIcon />} color="error" size="small" variant="outlined" sx={{ fontWeight: '600' }} /> :
                                    row?.original?.conversation_type === 'Agent' ?
                                      <Chip label={row?.original?.conversation_type} icon={<SupportAgentOutlinedIcon />} color="secondary" size="small" variant="outlined" sx={{ fontWeight: '600' }} /> :
                                      <Chip label={row?.original?.conversation_type} icon={<PersonOffOutlinedIcon />} color="warning" size="small" variant="outlined" sx={{ fontWeight: '600' }} />
                              }
                            </td>
                          );
                        }
                        else if (cell.column.Header === 'Sentiment') {
                          return (
                            <td {...cell.getCellProps()}>
                              {
                                row?.original?.analytics?.sentiment[0] === 'Positive' ?
                                  <Chip label={row?.original?.analytics?.sentiment[0]} color="success" size="small" variant="outlined" sx={{ fontWeight: '600' }} /> :
                                  row?.original?.analytics?.sentiment[0] === 'Negative' ?
                                    <Chip label={row?.original?.analytics?.sentiment[0]} color="error" size="small" variant="outlined" sx={{ fontWeight: '600' }} /> :
                                    row?.original?.analytics?.sentiment[0] === 'Neutral' ?
                                      <Chip label={row?.original?.analytics?.sentiment[0]} color="info" size="small" variant="outlined" sx={{ fontWeight: '600' }} /> :
                                      <div style={{ color: 'grey' }}>N/A</div>
                              }
                            </td>
                          );
                        }

                        else if (cell.column.Header === 'Created') {
                          return (
                            <td {...cell.getCellProps()}>
                              {row?.original?.created_date?.substring(0, 10) + " " + row?.original?.created_date?.substring(11, 16)}
                            </td>
                          );
                        }

                        else if (cell.column.Header === 'End User') {
                          return (
                            <td {...cell.getCellProps()}>
                              {(props.hideInfo && row?.original?.user_email !== 'anonymous') ? (
                                <>••{row?.original?.user_email?.slice(2, 5).trim()}•••{row?.original?.user_email?.slice(8, 11).trim()}••</>
                              ) : (
                                <>{row?.original?.user_email}</>
                              )}
                            </td>
                          );
                        }
                        // may be required later 
                        else if (cell.column.Header === 'Tags') {
                          return (
                            <td {...cell.getCellProps()}>
                              {
                                typeof row?.original?.tags === 'string'
                                  ? row?.original?.tags
                                  : row?.original?.tags?.map((tag) => <Chip label={tag.value} color="info" size="small" variant="outlined" sx={{ fontWeight: '600', mr: '5px' }} />)}
                            </td>
                          );
                        }
                        else if (cell.column.Header === 'Topics') {
                          return (
                            <td {...cell.getCellProps()}>
                              {typeof row?.original?.tags === 'string'
                                ? row?.original?.tags
                                : row?.original?.analytics?.intent?.map((tag) => { <Chip label={tag} color="info" size="small" variant="outlined" sx={{ fontWeight: '600', mr: '5px' }} /> })}
                              {row?.original?.analytics ? extractCoreText(row?.original?.analytics?.intent?.[0]) : ""}
                            </td>
                          );
                        }
                        else if (cell.column.Header === 'Queries') {
                          return (
                            <td {...cell.getCellProps()}>
                              {row?.original?.user_queries || 'N/A'}
                            </td>
                          );
                        }
                        else {
                          return (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </Body>
            </>) : <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}><h2 style={{ textAlign: "center" }}>Sorry! No Content in the Given time range</h2></div>}

          </>)}
      </RecordsTable>

      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
        isLastPage={!!(!(pageIndex < pageCount - 1) && !lastKey)}
      />

      {chatRecords !== null && (
        <CSVLink
          filename={'records.csv'}
          data={chatRecords.map((row) => {
            let data = {
              user_email: row?.user_email,
              created_date: row?.created_date,
              record_id: row?.record_id,
              conversation_type: row?.conversation_type,
              sentiment: row?.analytics?.sentiment[0],
              record: row?.record?.replace(/"/g, '""'),
              user_queries: row?.user_queries?.toString().replace(/"/g, '""'),
              topics: row?.analytics?.intent?.[0]?.replace(/"/g, '""') || '',
              tags: typeof row?.tags === 'object' ? row?.tags?.map((tag) =>  `${tag?.value || ''} `) : row?.tags //what to show in here
            };
            return data;
          })}
        >
          <Button
            variant="contained"
            size="small"
            startIcon={<FileUploadOutlinedIcon />}
            sx={{ textTransform: 'none' }}
          >
            Export Data
          </Button>
        </CSVLink>
      )}
    </Wrapper>
  );
};

// Exports:
export default Table;
