import { useMemo } from "react";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { recordsFilteredByPersonas } from "../utils";
import { selectSessionItems } from "../../../redux/selectors/chatSelectors";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

const options = {
    plugins: {
        title: {
            display: false,
            text: 'User Breakdown Chart',
        },
        tooltip: {
            filter: item => item.dataset.data[item.dataIndex] > 0
        },
        legend: {
            position: 'bottom',
            align: 'start',
            labels: {
                boxWidth: 20,
                boxHeight: 18,
                padding: 20
            },
        }
    },
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
    layout: {
        padding: 25
    }
};

const backgroundColors = [
    "rgb(6, 6, 6)",
    "rgb(112, 112, 112)",
    "rgb(169, 169, 169)",
    "rgb(204, 204, 204)",
    "rgb(233, 233, 233)"
];


const UserBreakDownChart = ({ startDate, endDate, persona }) => {
    const chatRecords = useSelector(selectSessionItems, shallowEqual);
    const personas = useSelector(state => state.content.personas);
    const allPersonas = ['Agent', 'Customer', ...personas];

    const { sortedValues, labels, total, personasData } = useMemo(() => {
        const diff = moment(endDate).diff(startDate, 'days');
        let filteredRecords = recordsFilteredByPersonas(chatRecords, personas, persona);

        const periodTypes = {
            '0': 'hour',
            '1': 'day',
            '31': 'month',
            '365': 'year',
        };

        let periodType = periodTypes['0']; // Default aggregation function
        Object.keys(periodTypes).forEach(key => {
            if (diff > parseInt(key)) {
                periodType = periodTypes[key];
            }
        });
        let personasData = allPersonas.reduce((obj, key) => ({ ...obj, [key]: [] }), {});
        return aggregateDataByPeriod(startDate, endDate, periodType, persona, personasData, filteredRecords)
    }, [chatRecords, persona])

    const mappedData = useMemo(() => Object.entries(personasData).map(([label, values]) => {
        let index = sortedValues.findIndex((el) => el[0] === label);
        return {
            label: label,
            borderColor: "black",
            backgroundColor: backgroundColors[index],
            borderWidth: 2,
            borderWidth: 2,
            fill: false,
            data: values,
            stack: "Stack 0",
        }
    }), [personasData]);

    let data = {
        labels,
        datasets: [
            {
                type: "line",
                label: "Total Sessions",
                borderColor: "black",
                borderWidth: 2,
                fill: false,
                data: total,
                pointRadius: 0,
                stack: "Stack 1",
                hidden: true,
            }
        ].concat(mappedData)
    };

    return (
        <>
            <Bar data={data} options={options} />
        </>
    );
}

export default UserBreakDownChart;

function aggregateDataByPeriod(startDate, endDate, periodType, persona, personasData, chatRecords) {
    let currentDate = moment(startDate).startOf(periodType);
    let labels = [];
    let total = [];
    let dateFormat = '';

    const periodFormats = {
        'year': 'YYYY',
        'month': 'MMM YYYY',
        'day': 'MMM D, YYYY',
        'hour': 'MMM D, YYYY HH:mm'
    };

    dateFormat = periodFormats[periodType] || 'MMM D, YYYY HH:mm';

    while (currentDate.isSameOrBefore(endDate)) {
        const start = moment(currentDate).startOf(periodType);
        const end = moment(currentDate).endOf(periodType);
        aggregateData(start, end, persona, personasData, chatRecords, total);
        labels.push(start.format(dateFormat));
        currentDate.add(1, periodType);
    }

    let sortedValues = [];
    for (let per in personasData) {
        let x = personasData[per].reduce((sum, val) => sum + val, 0)
        sortedValues.push([per, x]);
    }

    sortedValues.sort((a, b) => a[1] - b[1]);

    return {
        sortedValues: sortedValues.reverse(),
        labels,
        personasData,
        total
    }
}

function aggregateData(start, end, persona, personasData, chatRecords, total) {
    let tot = 0;
    let temp = Object.keys(personasData).reduce((obj, key) => ({ ...obj, [key]: 0 }), {});

    for (let i = 0; i < chatRecords?.length || 0; i++) {
        const record = chatRecords[i];
        if (moment(record.created_date).isBetween(start, end, null, '[]')) {
            if (!(persona in temp)) {
                for (let i = 0; i < record?.user_personas?.length || 0; i++) {
                    temp[record.user_personas[i]]++;
                }
                if (record?.user_email !== 'anonymous') temp['Agent']++;
                else temp['Customer']++;
            }
            else {
                if (record?.user_email !== 'anonymous' && persona === 'Agent') temp[persona]++;
                else if (persona === 'Customer' || record.user_personas.includes(persona)) temp[persona]++;
            }

        }
    }

    for (let x in temp) {
        if (personasData[x]) personasData[x].push(temp[x]);
    }

    total.push(tot);
}

/* Data Calculations End */
