import levenshtein from 'fast-levenshtein';
import { HELP_PHRASES, NEED_HELP_INTENT } from '../../constants/phrases';
import { GPT_MISHIT_REGEX, MARKDOWN_LINKS } from '../../constants/regex';

import {
  deleteKA,
  fetchResource,
  fetchSimilarity,
  getOrgData,
  listKA,
  agentStatus,
  getAgentType,
  fetchTrackingData,
  reactOnArticleApi,
  fetchReactionsApi,
  fetchShopifyOrderStatus,
  addDialogidMappingAPI,
  getAgents,
  agentOperations,
  createEnduserAccount,
  getUnverifiedArticlesAPI,
  deleteUnverifiedArticlesAPI,
  getUnverifiedArticlesOfSingleAgentAPI,
  autosaveAPI,
  deleteDraftAPI,
  getDraftsAPI,
  addFeedbackAPI,
  fetchFeedbackAPI,
  fetchFeedbackAPIV2,
  setArticleApi,
  setUnverifiedarticlesAPI,
  getOrgCustomizationData,
  getAllArticles,
  getArticleContent,
  reactOnArticleApiV2,
  fetchReactionsApiV2,
  fetchEventCount
} from "../../api";

import Amplify from 'aws-amplify';
import { setUserStatus } from './authActions';
import { addConversation, setBotTyping } from './chatActions';
import uuid from 'react-uuid';
import moment from 'moment';
import { ISO_DATE_FORMAT } from '../../constants/misc';

export const contentActions = {
  GET_AGENT_TYPE_BEGIN: 'GET_AGENT_TYPE_BEGIN',
  GET_AGENT_TYPE_SUCCESS: 'GET_AGENT_TYPE_SUCCESS',
  GET_AGENT_TYPE_ERROR: 'GET_AGENT_TYPE_ERROR',
  HANDLE_USER_STATUS_BEGIN: 'HANDLE_USER_STATUS_BEGIN',
  HANDLE_USER_STATUS_SUCCESS: 'HANDLE_USER_STATUS_SUCCESS',
  HANDLE_USER_STATUS_ERROR: 'HANDLE_USER_STATUS_ERROR',
  INTERACT_WITH_AI_BEGIN: 'INTERACT_WITH_AI_BEGIN',
  INTERACT_WITH_AI_SUCCESS_TRACK: 'INTERACT_WITH_AI_SUCCESS_TRACK',
  INTERACT_WITH_AI_ERROR: 'INTERACT_WITH_AI_ERROR',
  CLEAR_CONTENT_TRACK: 'CLEAR_CONTENT_TRACK',
  LOAD_SIMILARITY_BEGIN: 'LOAD_SIMILARITY_BEGIN',
  LOAD_SIMILARITY_SUCCESS: 'LOAD_SIMILARITY_SUCCESS',
  LOAD_SIMILARITY_ERROR: 'LOAD_SIMILARITY_ERROR',
  LOAD_KA_BEGIN: 'LOAD_KA_BEGIN',
  LOAD_KA_SUCCESS_TRACK: 'LOAD_KA_SUCCESS_TRACK',
  LOAD_KA_ERROR: 'LOAD_KA_ERROR',
  SET_ARTICLE_BEGIN: 'SET_ARTICLE_BEGIN',
  SET_ARTICLE_SUCCESS_TRACK: 'SET_ARTICLE_SUCCESS_TRACK',
  SET_ARTICLE_ERROR: 'SET_ARTICLE_ERROR',
  DELETE_KA_BEGIN: 'DELETE_KA_BEGIN',
  DELETE_KA_SUCCESS_TRACK: 'DELETE_KA_SUCCESS_TRACK',
  DELETE_KA_ERROR: 'DELETE_KA_ERROR',
  ORG_INFO_BEGIN: 'ORG_INFO_BEGIN',
  ORG_INFO_SUCCESS: 'ORG_INFO_SUCCESS',
  ORG_CUSTOM_INFO: 'ORG_CUSTOM_INFO',
  ORG_INFO_ERROR: 'ORG_INFO_ERROR',
  OPEN_ARTICLE_TRACK: 'OPEN_ARTICLE_TRACK',
  FETCH_ACTIVITY_RECORDS_BEGIN: 'FETCH_ACTIVITY_RECORDS_BEGIN',
  FETCH_ACTIVITY_RECORDS_SUCCESS_TRACK: 'FETCH_ACTIVITY_RECORDS_SUCCESS_TRACK',
  FETCH_ACTIVITY_RECORDS_ERROR: 'FETCH_ACTIVITY_RECORDS_ERROR',
  ADD_REACTION_BEGIN: 'ADD_REACTION_BEGIN',
  ADD_REACTION_SUCCESS_TRACK: 'ADD_REACTION_SUCCESS_TRACK',
  ADD_REACTION_ERROR: 'ADD_REACTION_ERROR',
  UPDATE_QUERY: 'UPDATE_QUERY',
  FETCH_REACTION_BEGIN: "FETCH_REACTION_BEGIN",
  FETCH_REACTION_BEGIN_V2: "FETCH_REACTION_BEGIN_V2",
  FETCH_REACTION_SUCCESS_TRACK: "FETCH_REACTION_SUCCESS_TRACK",
  FETCH_REACTION_SUCCESS_TRACK_V2: "FETCH_REACTION_SUCCESS_TRACK_V2",
  FETCH_REACTION_ERROR: "FETCH_REACTION_ERROR",
  FETCH_REACTION_ERROR_V2: "FETCH_REACTION_ERROR_V2",
  FETCH_ORDER_BEGIN: "FETCH_ORDER_BEGIN",
  FETCH_ORDER_SUCCESS: "FETCH_ORDER_SUCCESS",
  FETCH_ORDER_ERROR: "FETCH_ORDER_ERROR",
  FETCH_EVENTS_COUNT_BEGIN: "FETCH_EVENTS_COUNT_BEGIN",
  FETCH_EVENTS_COUNT_SUCCESS: "FETCH_EVENTS_COUNT_SUCCESS",
  FETCH_EVENTS_COUNT_ERROR: "FETCH_EVENTS_COUNT_ERROR",
  ADD_MAPPING_BEGIN: "ADD_MAPPING_BEGIN",
  ADD_MAPPING_SUCCESS: "ADD_MAPPING_SUCCESS",
  ADD_MAPPING_ERROR: "ADD_MAPPING_ERROR",
  GET_AGENTS_BEGIN: "GET_AGENTS_BEGIN",
  GET_AGENTS_SUCCESS: "GET_AGENTS_SUCCESS",
  GET_AGENTS_ERROR: "GET_AGENTS_ERROR",
  HANDLE_AGENTS_BEGIN: "HANDLE_AGENTS_BEGIN",
  HANDLE_AGENTS_SUCCESS: "HANDLE_AGENTS_SUCCESS",
  HANDLE_AGENTS_ERROR: "HANDLE_AGENTS_ERROR",
  CREATE_ENDUSER_ACCOUNT_BEGIN: "CREATE_ENDUSER_ACCOUNT_BEGIN",
  CREATE_ENDUSER_ACCOUNT_SUCCESS: "CREATE_ENDUSER_ACCOUNT_SUCCESS",
  CREATE_ENDUSER_ACCOUNT_ERROR: "CREATE_ENDUSER_ACCOUNT_ERROR",
  LOAD_UNVERIFIED_KA_BEGIN: "LOAD_UNVERIFIED_KA_BEGIN",
  LOAD_UNVERIFIED_KA_SUCCESS_TRACK: "LOAD_UNVERIFIED_KA_SUCCESS_TRACK",
  LOAD_UNVERIFIED_KA_ERROR: "LOAD_UNVERIFIED_KA_ERROR",
  SET_UNVERIFIED_KA_BEGIN: "SET_UNVERIFIED_KA_BEGIN",
  SET_UNVERIFIED_KA_SUCCESS_TRACK: "SET_UNVERIFIED_KA_SUCCESS_TRACK",
  SET_UNVERIFIED_KA_ERROR: "SET_UNVERIFIED_KA_ERROR",
  DELETE_UNVERIFIED_KA_BEGIN: "DELETE_UNVERIFIED_KA_BEGIN",
  DELETE_UNVERIFIED_KA_SUCCESS_TRACK: "DELETE_UNVERIFIED_KA_SUCCESS_TRACK",
  DELETE_UNVERIFIED_KA_ERROR: "DELETE_UNVERIFIED_KA_ERROR",
  LOAD_DRAFTS_BEGIN: "LOAD_DRAFTS_BEGIN",
  LOAD_DRAFTS_SUCCESS_TRACK: "LOAD_DRAFTS_SUCCESS_TRACK",
  LOAD_DRAFTS_ERROR: "LOAD_DRAFTS_ERROR",
  AUTOSAVE_BEGIN: "AUTOSAVE_BEGIN",
  AUTOSAVE_SUCCESS: "AUTOSAVE_SUCCESS",
  AUTOSAVE_ERROR: "AUTOSAVE_ERROR",
  DELETE_DRAFT_BEGIN: "DELETE_DRAFT_BEGIN",
  DELETE_DRAFT_SUCCESS_TRACK: "DELETE_DRAFT_SUCCESS_TRACK",
  DELETE_DRAFT_ERROR: "DELETE_DRAFT_ERROR",
  RENDER_WORKFLOW_URL_START: "RENDER_WORKFLOW_URL_START",
  RENDER_WORKFLOW_URL_SUCCESS: "RENDER_WORKFLOW_URL_SUCCESS",
  RENDER_WORKFLOW_URL_ERROR: "RENDER_WORKFLOW_URL_ERROR",
  ADD_FEEDBACK_BEGIN: "ADD_FEEDBACK_BEGIN",
  ADD_FEEDBACK_SUCCESS: "ADD_FEEDBACK_SUCCESS",
  ADD_FEEDBACK_ERROR: "ADD_FEEDBACK_ERROR",
  FETCH_FEEDBACK_BEGIN: "FETCH_FEEDBACK_BEGIN",
  FETCH_FEEDBACK_SUCCESS: "FETCH_FEEDBACK_SUCCESS",
  FETCH_FEEDBACK_ERROR: "FETCH_FEEDBACK_ERROR",
  SET_ORDER_STATUS: "SET_ORDER_STATUS",
  SET_LOADING: "SET_LOADING",
  //* Articles flow v2 actions
  LOAD_ARTICLES_BEGIN: "LOAD_ARTICLES_BEGIN",
  LOAD_ARTICLES_SUCCESS: "LOAD_ARTICLES_SUCCESS",
  LOAD_ARTICLES_ERROR: "LOAD_ARTICLES_ERROR",
  SET_WORKFLOWS: "SET_WORKFLOWS",
  // clear reaction events
  CLEAR_TABLE_REACTION_RECORDS: "CLEAR_TABLE_REACTION_RECORDS"
};

export const getContentActions = (dispatch) => {
  return {
    createQna: (payload, orgid) => dispatch(createQna(payload, orgid)),
    interact: (orgid, query, content, connectionOpened, event) =>
      dispatch(interact(orgid, query, content, connectionOpened, event)),
    similarity: (orgid, query, content) =>
      dispatch(similarity(orgid, query, content)),
    fetchKnowledgeArticles: (orgid) => dispatch(fetchKnowledgeArticles(orgid)),
    fetchEventCountBasedOnTypes: (orgid, startDate, endDate, events) => dispatch(fetchEventCountBasedOnTypes(orgid, startDate, endDate, events)),
    deleteKnowledgeArticle: (data) => dispatch(deleteKnowledgeArticle(data)),
    setKnowledgeArticle: (article) => dispatch(setKnowledgeArticle(article)),
    getOrganisationInfo: (org) => dispatch(getOrganisationInfo(org)),
    getOrganisationCustomizationInfo: (org) =>
      dispatch(getOrganisationCustomizationInfo(org)),
    fetchActivityRecords: (orgid) => dispatch(fetchActivityRecords(orgid)),
    reactOnArticle: (data) => dispatch(reactOnArticle(data)),
    reactOnArticleV2: (data) => dispatch(reactOnArticleV2(data)),
    fetchReactions: (orgid) => dispatch(fetchReactions(orgid)),
    fetchReactionsV2: (orgid, startDate, endDate, requestType) => dispatch(fetchReactionsV2(orgid, startDate, endDate, requestType)),
    fetchOrderStatus: (orderId) => dispatch(fetchOrderStatus(orderId)),
    reactOnArticleV2: (data) => dispatch(reactOnArticleV2(data)),
    addDialogidMapping: (orgid, articleId, dialog_id) =>
      dispatch(addDialogidMapping(orgid, articleId, dialog_id)),
    fetchAgents: (orgId) => dispatch(fetchAgents(orgId)),
    handleAgentOperations: (
      action,
      orgId,
      userId,
      firstName,
      lastName,
      role,
      slackId,
      personas,
      userpoolId
    ) =>
      dispatch(
        handleAgentOperations(
          action,
          orgId,
          userId,
          firstName,
          lastName,
          role,
          slackId,
          personas,
          userpoolId
        )
      ),
    createEnduserAccountAction: (email) =>
      dispatch(createEnduserAccountAction(email)),
    fetchUnverifiedKnowledgeArticles: (orgid) =>
      dispatch(fetchUnverifiedKnowledgeArticles(orgid)),
    deleteUnverifiedKnowledgeArticle: (data) =>
      dispatch(deleteUnverifiedKnowledgeArticle(data)),
    setUnverifiedKnowledgeArticle: (article) =>
      dispatch(setUnverifiedKnowledgeArticle(article)),
    fetchUnverifiedKAOfSingleAgent: (orgid, createdBy) =>
      dispatch(fetchUnverifiedKAOfSingleAgent(orgid, createdBy)),
    getDrafts: (orgid, createdBy) => dispatch(getDrafts(orgid, createdBy)),
    deleteDraft: (data) => dispatch(deleteDraft(data)),
    autosave: (article) => dispatch(autosave(article)),
    renderWorkflowUrl: (url) => dispatch(renderWorkflowUrl(url)),

    addFeedback: (orgid, user, rating, comment, type) =>
      dispatch(addFeedback(orgid, user, rating, comment, type)),
    fetchFeedback: (orgid) => dispatch(fetchFeedback(orgid)),
    fetchFeedbackV2: (orgid, startDate, endDate) => dispatch(fetchFeedbackV2(orgid, startDate, endDate)),
    loadArticles: (orgId, user, startKey = null) => dispatch(loadArticles(orgId, user, startKey))
  };
};

export const setLoading = (payload) => {
  return {
    type: contentActions.SET_LOADING,
    payload
  };
};

/***************************LEAVE CHAT***************************************** */

export const clearContent = () => {
  return {
    type: contentActions.CLEAR_CONTENT_TRACK
  };
};

/****************Open article  ************** */

export const createQna = (payload, orgid) => {
  return async (dispatch, getState) => {
    dispatch({
      type: contentActions.OPEN_ARTICLE_TRACK,
      payload
    });
  };
};

export const updateQueryInStore = (payload) => {
  return {
    type: contentActions.UPDATE_QUERY,
    payload
  };
};

/**********************************Order Status ****************** */

export const setOrderStatus = (payload) => {
  return {
    type: contentActions.SET_ORDER_STATUS,
    payload
  };
};

/************************************INTERACT************************************/

export const interact = (orgid, query, content, typedInChat, eventType) => {
  return async (dispatch, getState) => {
    dispatch({ type: contentActions.INTERACT_WITH_AI_BEGIN });
    try {
      const reduxState = getState();
      if (!content) {
        content = await fetchResource(
          orgid, query,
          reduxState?.content?.agent_type ?? 'endUser',
          reduxState?.content?.agent_personas ?? []
        );
      }
      const tempQna = content?.qna?.payload?.response;
      const tempIntent = content?.intent?.payload?.response;
      const workflows = content?.workflows;
      const workflowId = content?.workflows?.[0]?.meta?.id;
      const workflowName = content?.workflows?.[0]?.meta?.title;
      const ticketId = content?.workflows?.[0]?.meta?.ticketId;

      let qna = {
        ...tempQna,
        question: tempQna?.name
      };

      let intent = {
        ...tempIntent,
        question: tempIntent?.name
      };

      delete qna['name'];
      delete intent['name'];

      if (!intent?.meta) {
        intent['meta'] = {
          formattedText: intent?.result
        };
      }

      const isMishit = intent?.result === 'Bad input received' ||
        GPT_MISHIT_REGEX.test(intent?.result ?? "");

      if (typedInChat && !getState().chat?.agentChatWorkflowStarted) {
        // if (workflowId &&  !isMishit) {
        if (workflowId) {
          dispatch(
            addConversation({
              type: 'bot',
              subtype: 'received',
              message: 'We found a workflow related to your query',
              button: workflowName
            })
          );
        }

        if (intent?.question?.length === 0) {
          dispatch(
            addConversation({
              type: 'bot',
              subtype: 'received',
              message: 'Can you please elaborate?'
            })
          );
          dispatch(setBotTyping(false));
        } else if(workflowId && !intent?.result){
        }
         else if (!workflowId && isMishit) {
          dispatch(
            addConversation({
              type: "bot",
              subtype: "received",
              message:
                "Sorry we couldn’t resolve your issue. Please click to connect with our support team.",
            })
          );
          dispatch(setBotTyping(false));
        } else if (isMishit) {
          dispatch(
            addConversation({
              type: "bot",
              subtype: "received",
              message:
                "Sorry we couldn’t resolve your issue. Please click to connect with our support team.",
            }),
          );
          dispatch(setBotTyping(false));
        } else if (intent?.question === "Connect_To_Live_Agent" || intent?.result === NEED_HELP_INTENT) {
          dispatch(
            addConversation({
              type: "bot",
              subtype: "received",
              message: "We noticed that you might need some assistance.",
            })
          );
          dispatch(setBotTyping(false));
        } else {
          if (intent?.result !== "Bad input received") {
            const sources = [];
            const appendSource = (meta) => {
              try {
                const urlObj = new URL(meta?.source);
                sources.push({
                  url: urlObj.origin + urlObj.pathname,
                  title: meta?.title ?? urlObj.hostname,
                  pageNumber: meta?.page_number
                });
              } catch(error) {
                console.error(error);
              }
            }
            if (Array.isArray(intent?.meta)) {
              for (const item of intent.meta) {
                appendSource(item);
              }
            } else {
              appendSource(intent?.meta);
            }
            let message = intent?.result;
            try {
              const matches = [...message.matchAll(MARKDOWN_LINKS)];
              for (const match of matches) {
                message = message.replace(
                  match[0],
                  `<a target="_blank" rel="noreferrer" href="${match[2]}">${match[1]}</a>`
                );
              }
            } catch (error) {
              console.error(error);
              message = intent?.result;
            }
            dispatch(
              addConversation({
                type: 'bot',
                subtype: 'received',
                sources: sources,
                message: message
              })
            );
            dispatch(setBotTyping(false));
          }
        }
      }

      const payload = { qna, intent };
      if (workflowId) payload["workflow"] = { workflowId, workflowName, ticketId };

      dispatch({
        type: contentActions.SET_WORKFLOWS,
        workflows,
      });
      dispatch({
        type: contentActions.INTERACT_WITH_AI_SUCCESS_TRACK,
        payload:{
          qna:{},
          intent:{},
          workflow: {
            workflowId,
            workflowName,
            ticketId
          }
        }
      });
      const data = {
        orgid,
        user_email: getState().auth.userDetails?.email || "anonymous",
        type: "mishit",
        subtype: "interact",
        article_id: "",
        title: "",
        query: getState().content.query,
      };
      const data_V2 = {
        orgId: orgid,
        userEmail: getState().auth.userDetails?.email || "anonymous",
        eventType: "mishit",
        subtype: "interact",
        eventId: uuid(),
        title: "",
        query: getState().content.query || "",
      };
      if (isMishit && (eventType === "Enter" || eventType === "click")) {
        dispatch(reactOnArticle(data)); //mishit
        dispatch(reactOnArticleV2(data_V2)); 
      }
    } catch (error) {
      dispatch({
        type: contentActions.INTERACT_WITH_AI_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

const checkResponseAgainstRole = (intent, getState) => {
  let role = getState().auth?.user?.role || null;
  let target = intent?.meta?.target || null;
  if (!role || !target) {
    return true;
  }
  if (role === "endUser") {
    // Only endUser targeted response can be allowed
    if (target === role) {
      return true;
    }
  } else if (role === "partner") {
    if (target === "endUser" || target === "partner") {
      return true;
    }
  } else if (role === "agent") {
    return true;
  }
  return false;
};

/************************SIMILARITY******************************* */

export const similarity = (orgid, query, content) => {
  return async (dispatch, getState) => {
    dispatch({ type: contentActions.LOAD_SIMILARITY_BEGIN });
    try {
      if (!content) {
        const reduxState = getState();
        content = await fetchSimilarity(
          orgid, query,
          reduxState?.content?.agent_type ?? 'endUser',
          reduxState?.content?.agent_personas ?? []
        );
      }
      dispatch({
        type: contentActions.LOAD_SIMILARITY_SUCCESS,
        payload: content
      });
      if (Object.keys(content).length === 0) {
        const data = {
          orgid,
          user_email: `${getState().auth.userDetails?.email || "anonymous"}`,
          type: "mishit",
          subtype: "similarity",
          dialog_id: "",
          title: "",
          query: getState().content.query,
        };
        dispatch(reactOnArticle(data));
      }
    } catch (error) {
      dispatch({
        type: contentActions.LOAD_SIMILARITY_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

/****************************KNOWLEDGE ARTICLES*********************************** */

export const fetchKnowledgeArticles = (orgid) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.LOAD_KA_BEGIN });
    try {
      const content = await listKA(orgid);
      dispatch({
        type: contentActions.LOAD_KA_SUCCESS_TRACK,
        payload: content
      });
    } catch (error) {
      dispatch({
        type: contentActions.DELETE_KA_ERROR,
        payload: {
          code: error.code,
          message: error.message
        }
      });
      throw error;
    }
  };
};

export const deleteKnowledgeArticle = (data) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.DELETE_KA_BEGIN });
    try {
      const content = await deleteKA(data);
      dispatch({
        type: contentActions.DELETE_KA_SUCCESS_TRACK,
        payload: content
      });
      await dispatch(fetchKnowledgeArticles(data.orgid));
    } catch (error) {
      const err = error?.response?.data;
      dispatch({
        type: contentActions.DELETE_KA_ERROR,
        payload: {
          code: err.code,
          message: err.message
        }
      });
      throw error;
    }
  };
};

export const setKnowledgeArticle = (article) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.SET_ARTICLE_BEGIN });
    try {
      const res = await setArticleApi(article);
      dispatch({
        type: contentActions.SET_ARTICLE_SUCCESS_TRACK,
        payload: res
      });
      dispatch(
        addDialogidMapping(article?.orgid, res?.meta?.articleId, res?.dialog_id)
      );
    } catch (error) {
      const err = error?.response?.data;
      dispatch({
        type: contentActions.SET_ARTICLE_ERROR,
        payload: {
          code: err?.code,
          message: err?.message
        }
      });
      throw error;
    }
  };
};

/**************************************Unverified knowledge article ******************* */

export const fetchUnverifiedKnowledgeArticles = (orgid) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.LOAD_UNVERIFIED_KA_BEGIN });
    try {
      const content = await getUnverifiedArticlesAPI(orgid);
      dispatch({
        type: contentActions.LOAD_UNVERIFIED_KA_SUCCESS_TRACK,
        payload: content
      });
    } catch (error) {
      const err = error?.response?.data;
      dispatch({
        type: contentActions.LOAD_UNVERIFIED_KA_ERROR,
        payload: {
          code: err.code,
          message: err.message
        }
      });
      throw error;
    }
  };
};

export const deleteUnverifiedKnowledgeArticle = (data) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.DELETE_UNVERIFIED_KA_BEGIN });
    try {
      const content = await deleteUnverifiedArticlesAPI(data);
      dispatch({
        type: contentActions.DELETE_UNVERIFIED_KA_SUCCESS_TRACK,
        payload: content
      });
      if (data?.createdBy) {
        await dispatch(
          fetchUnverifiedKAOfSingleAgent(data.orgid, data.createdBy)
        );
      } else {
        await dispatch(fetchUnverifiedKnowledgeArticles(data.orgid));
      }
    } catch (error) {
      const err = error?.response?.data;
      dispatch({
        type: contentActions.DELETE_UNVERIFIED_KA_ERROR,
        payload: {
          code: err.code,
          message: err.message
        }
      });
      throw error;
    }
  };
};

export const setUnverifiedKnowledgeArticle = (article) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.SET_UNVERIFIED_KA_BEGIN });
    try {
      const content = await setUnverifiedarticlesAPI(article);
      dispatch({
        type: contentActions.SET_UNVERIFIED_KA_SUCCESS_TRACK,
        payload: content
      });
      dispatch(createQna(content, article.orgid));
    } catch (error) {
      const err = error?.response?.data;
      dispatch({
        type: contentActions.SET_UNVERIFIED_KA_ERROR,
        payload: {
          code: err.code,
          message: err.message
        }
      });
      throw error;
    }
  };
};

export const fetchUnverifiedKAOfSingleAgent = (orgid, createdBy) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.LOAD_UNVERIFIED_KA_BEGIN });
    try {
      const content = await getUnverifiedArticlesOfSingleAgentAPI(
        orgid,
        createdBy
      );
      dispatch({
        type: contentActions.LOAD_UNVERIFIED_KA_SUCCESS_TRACK,
        payload: content
      });
    } catch (error) {
      const err = error?.response?.data;
      dispatch({
        type: contentActions.LOAD_UNVERIFIED_KA_ERROR,
        payload: {
          code: err.code,
          message: err.message
        }
      });
      throw error;
    }
  };
};

/**********************************AUTOSAVE ***********************************************/

export const autosave = (article) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.AUTOSAVE_BEGIN });
    try {
      const content = await autosaveAPI(article);

      dispatch({
        type: contentActions.AUTOSAVE_SUCCESS,
        payload: content
      });
    } catch (error) {
      const err = error?.response?.data;
      dispatch({
        type: contentActions.AUTOSAVE_ERROR,
        payload: {
          code: err.code,
          message: err.message
        }
      });
      throw error;
    }
  };
};

export const deleteDraft = (data) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.DELETE_DRAFT_BEGIN });
    try {
      const content = await deleteDraftAPI(data);
      dispatch({
        type: contentActions.DELETE_DRAFT_SUCCESS_TRACK,
        payload: content
      });
      await dispatch(getDrafts(data.orgid, data.createdBy));
    } catch (error) {
      const err = error?.response?.data;
      dispatch({
        type: contentActions.DELETE_DRAFT_ERROR,
        payload: {
          code: err.code,
          message: err.message
        }
      });
      throw error;
    }
  };
};

export const getDrafts = (orgid, createdBy) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.LOAD_DRAFTS_BEGIN });
    try {
      const content = await getDraftsAPI(orgid, createdBy);
      dispatch({
        type: contentActions.LOAD_DRAFTS_SUCCESS_TRACK,
        payload: content
      });
    } catch (error) {
      const err = error?.response?.data;
      dispatch({
        type: contentActions.LOAD_DRAFTS_ERROR,
        payload: {
          code: err.code,
          message: err.message
        }
      });
      throw error;
    }
  };
};

export const fetchAgentType = (org_id, email) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.GET_AGENT_TYPE_BEGIN });
    try {
      const content = await getAgentType(org_id, email);
      dispatch({
        type: contentActions.GET_AGENT_TYPE_SUCCESS,
        payload: content.body
      });
    } catch (error) {
      dispatch({
        type: contentActions.GET_AGENT_TYPE_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

/**************************************Tracking data******************* */

export const fetchActivityRecords = (orgid) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.FETCH_ACTIVITY_RECORDS_BEGIN });
    try {
      const content = await fetchTrackingData(orgid);
      const payload = [...content];
      payload.forEach((record) => {
        delete record['orgid'];
      });
      dispatch({
        type: contentActions.FETCH_ACTIVITY_RECORDS_SUCCESS_TRACK,
        payload
      });
    } catch (error) {
      dispatch({
        type: contentActions.FETCH_ACTIVITY_RECORDS_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

/************************************************************************** */

export const getOrganisationCustomizationInfo = (org) => {
  return async (dispatch) => {
    try {
      const orgData = await getOrgCustomizationData(org);
      dispatch({
        type: contentActions.ORG_CUSTOM_INFO,
        payload: orgData,
      });
    } catch (error) {
      dispatch({
        type: contentActions.ORG_INFO_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};
/************************************************************************** */

export const getOrganisationInfo = (org) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.ORG_INFO_BEGIN });
    try {
      const content = await getOrgData(org);
      localStorage.setItem('org_name', content['org_data']['orgName']);
      localStorage.setItem('org_id', content['org_data']['_id']);
      localStorage.setItem(
        'validate_enduser',
        content['org_data']['validate_enduser']
      );
      localStorage.setItem(
        'agent_reply_timeout',
        content['org_data']['slack']['agent_reply_timeout'] * 1000
      );
      if (content['org_data']['ticketing']['platform'] !== '') {
        localStorage.setItem(
          'ticketing_system',
          content['org_data']['ticketing']['platform']
        );
        localStorage.setItem("supportEmail", content?.org_data?.ticketing?.support_email ?? "");
        localStorage.setItem("senderEmail", content?.org_data?.ticketing?.sender_email ?? "");
      } else {
        localStorage.removeItem('ticketing_system');
        localStorage.removeItem("supportEmail");
        localStorage.removeItem("senderEmail");
      }
      Amplify.configure({
        Auth: {
          region: content['org_data']['cognito']['region'],
          userPoolId: content['org_data']['cognito']['userpool_id'],
          userPoolWebClientId: content['org_data']['cognito']['client_id']
        }
      });
      dispatch({
        type: contentActions.ORG_INFO_SUCCESS,
        payload: content
      });
      dispatch(
        setUserStatus({
          org_id: content?.org_data?._id,
          role: 'endUser',
          isAuthenticated: false
        })
      );
    } catch (error) {
      dispatch({
        type: contentActions.ORG_INFO_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

/*********************************AGENT STATUS********************************* */

export const handleAgentStatus = (action, org_id, user_id, status) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.HANDLE_USER_STATUS_BEGIN });
    try {
      const content = await agentStatus(action, org_id, user_id, status);
      dispatch({
        type: contentActions.HANDLE_USER_STATUS_SUCCESS,
        payload: content.body
      });
    } catch (error) {
      dispatch({
        type: contentActions.HANDLE_USER_STATUS_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

/**********************************ADD REACTIONS*************************************/

export const reactOnArticle = (data) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.ADD_REACTION_BEGIN });
    try {
      await reactOnArticleApi({ ...data, createdAt: new Date().toISOString() });
      dispatch({
        type: contentActions.ADD_REACTION_SUCCESS_TRACK
      });
    } catch (error) {
      dispatch({
        type: contentActions.ADD_REACTION_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

export const reactOnArticleV2 = (data) => {
  let previous_interactionRecords_string = localStorage.getItem('InteractionsRecords');
  let interaction_records = previous_interactionRecords_string ? JSON.parse(previous_interactionRecords_string) : [];
  interaction_records.push({"id": data.eventId});
  localStorage.setItem(
    'InteractionsRecords',
    JSON.stringify(interaction_records)
  )
  return async (dispatch) => {
    dispatch({ type: contentActions.ADD_REACTION_BEGIN });
    try {
      await reactOnArticleApiV2({ ...data });
      dispatch({
        type: contentActions.ADD_REACTION_SUCCESS_TRACK,
      });
    } catch (error) {
      dispatch({
        type: contentActions.ADD_REACTION_ERROR,
        payload: error?.response?.data?.message,
      });
      throw error;
    }
  };
};

export const fetchReactions = (orgid) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.FETCH_REACTION_BEGIN });
    try {
      const response = await fetchReactionsApi(orgid);
      dispatch({
        type: contentActions.FETCH_REACTION_SUCCESS_TRACK,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: contentActions.FETCH_REACTION_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

export const fetchReactionsV2 = (orgid, startDate, endDate, requestType, filter = {}) => {
  return async (dispatch, getState) => {
    dispatch({ type: contentActions.FETCH_REACTION_BEGIN_V2 });
    try {
      const filter = { lastKey: getState()?.content?.reactionRecordsLastKey };
      const response = await fetchReactionsApiV2(orgid, startDate, endDate, requestType, filter);
      response?.data?.Events?.forEach((event) => {
        event['created_date'] = moment.utc(event['created_date']).local().format(ISO_DATE_FORMAT)
      })
      const reactions = response?.data?.Events;
      const lastKey = response?.data?.lastKey;
      dispatch({
        type: contentActions.FETCH_REACTION_SUCCESS_TRACK_V2,
        payload: {reactions, lastKey},
      });
    } catch (error) {
      dispatch({
        type: contentActions.FETCH_REACTION_ERROR_V2,
        payload: error?.response?.data?.message,
      });
      throw error;
    }
  };
};

export const fetchEventCountBasedOnTypes = (orgid, startDate, endDate, events) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.FETCH_EVENTS_COUNT_BEGIN })
    try {
      const promises = events.map(({ eventType, subType }) => fetchEventCount(orgid, startDate, endDate, eventType, subType));
      const content = await Promise.all(promises);
      let eventCount = {};
      for (const res of content){
        eventCount[res.data.event_type] = res.data.event_count;
      }
      dispatch({
        type: contentActions.FETCH_EVENTS_COUNT_SUCCESS,
        payload: eventCount
      })
    } catch (error) {
      dispatch({
        type: contentActions.FETCH_EVENTS_COUNT_ERROR,
        payload: error?.response?.data?.message
      })
      throw error
    }
  }
}

export const fetchOrderStatus = (orderId) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.FETCH_ORDER_BEGIN });
    try {
      const response = await fetchShopifyOrderStatus(orderId);
      dispatch({
        type: contentActions.FETCH_ORDER_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: contentActions.ORDER,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

/************************Add dialog id mapping to dynamo *****************/

export const addDialogidMapping = (orgid, articleId, dialog_id) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.ADD_MAPPING_BEGIN });
    try {
      await addDialogidMappingAPI(orgid, articleId, dialog_id);
      dispatch({
        type: contentActions.ADD_MAPPING_SUCCESS
      });
    } catch (error) {
      dispatch({
        type: contentActions.ADD_MAPPING_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

export const fetchAgents = (orgId) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.GET_AGENTS_BEGIN });
    try {
      const content = await getAgents(orgId);
      dispatch({
        type: contentActions.GET_AGENTS_SUCCESS,
        payload: content
      });
    } catch (error) {
      dispatch({
        type: contentActions.GET_AGENTS_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

export const handleAgentOperations = (
  action,
  orgId,
  userId,
  firstName,
  lastName,
  role,
  slackId,
  personas,
  userpoolId
) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.HANDLE_AGENTS_BEGIN });
    try {
      const content = await agentOperations(
        action,
        orgId,
        userId,
        firstName,
        lastName,
        role,
        slackId,
        personas,
        userpoolId
      );
      dispatch(fetchAgents(orgId));
      dispatch({
        type: contentActions.HANDLE_AGENTS_SUCCESS,
        payload: content
      });
    } catch (error) {
      dispatch({
        type: contentActions.HANDLE_AGENTS_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

export const createEnduserAccountAction = (email) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.CREATE_ENDUSER_ACCOUNT_BEGIN });
    try {
      const content = await createEnduserAccount(email);
      dispatch({
        type: contentActions.CREATE_ENDUSER_ACCOUNT_SUCCESS,
        payload: content
      });
    } catch (error) {
      dispatch({
        type: contentActions.CREATE_ENDUSER_ACCOUNT_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

export const renderWorkflowUrl = (url) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.RENDER_WORKFLOW_URL_START });
    try {
      dispatch({
        type: contentActions.RENDER_WORKFLOW_URL_SUCCESS,
        payload: url
      });
    } catch (error) {
      dispatch({
        type: contentActions.RENDER_WORKFLOW_URL_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

/***************Feedback************************ */

export const addFeedback = (orgid, user, rating, comment, type) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.ADD_FEEDBACK_BEGIN });
    try {
      await addFeedbackAPI(orgid, user, rating, comment, type);
      dispatch({
        type: contentActions.ADD_FEEDBACK_SUCCESS
      });
      localStorage.removeItem('validation_attempts');
      localStorage.removeItem('validation_comments');
    } catch (error) {
      dispatch({
        type: contentActions.ADD_FEEDBACK_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

export const fetchFeedback = (orgid) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.FETCH_FEEDBACK_BEGIN });
    try {
      const response = await fetchFeedbackAPI(orgid);
      const feedback = response.map((res) => ({
        ...res,
        reaction: res.rating
      }));
      dispatch({
        type: contentActions.FETCH_FEEDBACK_SUCCESS,
        payload: feedback
      });
    } catch (error) {
      dispatch({
        type: contentActions.FETCH_FEEDBACK_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

export const fetchFeedbackV2 = (orgid, startDate, endDate) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.FETCH_FEEDBACK_BEGIN });
    try {
      const response = await fetchFeedbackAPIV2(orgid, startDate, endDate);
      // const feedback = response.map((res) => ({
      //   ...res,
      //   reaction: res.rating,
      // }));
      dispatch({
        type: contentActions.FETCH_FEEDBACK_SUCCESS,
        payload: response,
        // payload: feedback,
      });
    } catch (error) {
      dispatch({
        type: contentActions.FETCH_FEEDBACK_ERROR,
        payload: error?.response?.data?.message,
      });
      throw error;
    }
  };
};

/************************ Articles flow v2 ************************/

export const loadArticles = (orgId, user, startKey) => {
  return async (dispatch) => {
    dispatch({ type: contentActions.LOAD_ARTICLES_BEGIN });
    try {
      const response = await getAllArticles(orgId, user, startKey);
      dispatch({
        type: contentActions.LOAD_ARTICLES_SUCCESS,
        payload: {
          ...response?.data,
          reset: !startKey
        }
      });
    } catch (error) {
      dispatch({
        type: contentActions.LOAD_ARTICLES_ERROR,
        payload: error
      });
      throw error;
    }
  };
};

// ********************* Clear Events *************************** //

export const clearTableReactionRecords = () => { 
  return {
    type: contentActions.CLEAR_TABLE_REACTION_RECORDS
  }
}
