// Packages:
import React, { useCallback, useRef, useState } from 'react';
import { 
  MenuItem,
  FormControl,
  Select,
  TextField,
  InputLabel,
  styled,
  Box,
  Button
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getChatActions } from '../../../redux/actions/chatActions';


// Styles:
import { Wrapper} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getContentActions } from '../../../redux/actions/contentActions';
import useFilterHook from '../../../hooks/useFilterHook';
import { getRequestTypes } from '../utils';
import { CHART_CHAT_FILTER_PARAMS } from '../../../constants/api';
import { ISO_DATE_FORMAT } from '../../../constants/misc';
import { EVENT_TYPES, SUB_EVENTS } from '../../../constants/events';

const StyledFormControl = styled(FormControl)`
  margin-top: 1rem;
  & .MuiFormLabel-root {
    font-size: 14px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 14px;
  }
  & .MuiFormGroup-root {
    margin-left: 1rem;
    font-size: 14px;
    & .MuiTypography-root {
      font-size: 14px;
    }
  }
  &. MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: red;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledDesktopDatePicker = styled(DesktopDatePicker)`
  &. MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 14px;
  }
  & .MuiFormControl-root {
    margin-top: 1rem;
  }
`;
const fetchEvents = [
  { eventType: EVENT_TYPES.WIDGET_READY_EVENT, subType: EVENT_TYPES.WIDGET_READY_EVENT },
  { eventType: EVENT_TYPES.WIDGET_OPEN_EVENT, subType: SUB_EVENTS.WIDGET_SUB_EVENT },
  { eventType: EVENT_TYPES.WORKFLOW_START_EVENT, subType: SUB_EVENTS.WORKFLOW_SUB_EVENT },
]
// Functions:
const Filters = (props) => {
  const { handlePersonaChange } = props;
  const personas = useSelector(state => state.content.personas);
  const dispatch = useDispatch();
  const chatActions = getChatActions(dispatch)
  const contentActions = getContentActions(dispatch);
  const { handleStartDateChange, handleEndDateChange, handleDateRangeChange, applyFilterFuncExecution, applyFilterDisable } = useFilterHook(props);

  // Event Handlers:
  const handleApplyFilter = () =>{
    applyFilterFuncExecution(() => {
      let { chatRequestType, eventRequestType } = getRequestTypes(props.startDate, props.endDate);
      const duration = moment(props.endDate).diff(moment(props.startDate), 'days');
      const baselineStartDate = moment(props.startDate).subtract(duration, 'days').format(ISO_DATE_FORMAT);
      const baselineEndDate = moment(props.endDate).subtract(duration, 'days').format(ISO_DATE_FORMAT);
      let { chatRequestType: baselineChatType, eventRequestType: baselineEventType } = getRequestTypes(baselineStartDate, baselineEndDate);
      chatActions.fetchBaseLineChatRecordsV3(props.orgId, baselineStartDate, baselineEndDate, baselineChatType, CHART_CHAT_FILTER_PARAMS);
      chatActions.fetchChatRecordsV3(props.orgId, props.startDate, props.endDate, chatRequestType, CHART_CHAT_FILTER_PARAMS);
      contentActions.fetchReactionsV2(props.orgId, props.startDate, props.endDate, eventRequestType);
      if(duration <= 1){
        contentActions.fetchEventCountBasedOnTypes(props.orgId, props.startDate, props.endDate, fetchEvents);
      }
    })
  };

  // Return:
  return (
    <Wrapper sx={{ backgroundColor: 'transparent' }}>
  <Box sx={{
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'flex-start', // Align to the left
    gap: '1rem', // Add space between filter boxes
    alignItems: 'flex-start', // Align items vertically with flex-start
  }}>
    <StyledFormControl sx={{ width: '10%', height: '100%' }}> {/* Set width and height */}
      <InputLabel id="date-range">Filter By</InputLabel>
      <Select
        autoWidth={false}
        id="date-range"
        value={props.dateRange}
        onChange={(event) => handleDateRangeChange(event)}
        label="Date Range"
        sx={{ fontSize: 14 }}
      >
        <StyledMenuItem value="custom" key="custom">Custom</StyledMenuItem>
        <StyledMenuItem value="today" key="today">Today</StyledMenuItem>
        <StyledMenuItem value="yesterday" key="yesterday">Yesterday</StyledMenuItem>
        <StyledMenuItem value="this week" key="this week">7 Days</StyledMenuItem>
        <StyledMenuItem value="this month" key="this month">1 Month</StyledMenuItem>
        <StyledMenuItem value="this year" key="this year">1 Year</StyledMenuItem>
      </Select>
    </StyledFormControl>
    <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ width: '25%', height: '100%' }}> {/* Set width and height */}
      <div style={{ marginTop: '1rem' }}>
        <StyledDesktopDatePicker
          variant="inline"
          label="Start Date"
          value={props.startDate}
          onChange={handleStartDateChange}
          maxDate={props.endDate}
          renderInput={(params) => <TextField {...params} />}
        />
      </div>
    </LocalizationProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns} sx={{ width: '25%', height: '100%' }}> 
      <div style={{ marginTop: '1rem' }}>
        <StyledDesktopDatePicker
          label="End Date"
          value={props.endDate}
          onChange={handleEndDateChange}
          minDate={props.startDate}
          renderInput={(params) => <TextField {...params} />}
        />
      </div>
    </LocalizationProvider>
    <StyledFormControl sx={{ width: '10%', height: '100%' }}>
          <InputLabel id="persona-filter">Persona</InputLabel>
          <Select
            autoWidth={false}
            id="persona-filter"
            value={props.persona}
            onChange={handlePersonaChange}
            label="Persona"
            sx={{ fontSize: 14 }}
          >
            <StyledMenuItem value="All Persona">All Persona</StyledMenuItem>
            <StyledMenuItem value="Agent">Agent</StyledMenuItem>
            <StyledMenuItem value="Customer">Customer</StyledMenuItem>
            {
              personas.filter(persona => persona !== 'Agent' && persona !== 'Customer').map(persona => (
                <StyledMenuItem key={persona} value={persona}>{persona}</StyledMenuItem>
              ))
            }
          </Select>
        </StyledFormControl>

    <Button 
      variant="contained"
      size="medium"
      sx={{ textTransform: 'none', width: '10%', height: '100%', marginTop: '1.5rem' }} 
      onClick={() => handleApplyFilter()}
      disabled={applyFilterDisable}
    >
        Apply Filters
    </Button>
  </Box>
</Wrapper>
  );
};

// Exports:
export default Filters;
