// Packages:
import React, { memo, useCallback, useEffect, useState } from "react";
import { MenuItem, FormControl, Select, styled, TextField } from "@mui/material";

// Styles:
import { FieldName } from "../styles";
import BaseConfiguration from "../BaseConfiguration";
import { DEFAULT_OUTPUT_ADDRESS, DEFAULT_OUTPUT_EMAIL, DEFAULT_OUTPUT_ORDERID, SHOPIFY_RESPONSE_FIELDS, SHOPIFYFUNCTIONALITY } from "./utils";
import AutocompleteGlobal from "../../Components/AutocompleteGlobal";
const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;


// Functions:
const ShopifyConfiguration = ({ node, updateNode, deleteNode, isEditable, currentRemoteWorkflow, setNodes }) => {
  // State:
  const [shopifyAction, setShopifyAction] = useState(
    node.shopifyAction
  );
  const [orderId, setOrderId] = useState(node.orderId ?? DEFAULT_OUTPUT_ORDERID)
  const [email, setEmail] = useState(node.email ?? DEFAULT_OUTPUT_EMAIL)
  const [validateUserEmail, setValidateUserEmail] = useState(node.validateUserEmail ?? false)
  const [address, setAddress] = useState(node.address ?? DEFAULT_OUTPUT_ADDRESS)
  const [validateUserAddress, setValidateUserAddress] = useState(node.validateUserAddress ?? false)
  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields, setOutputFields] = useState([])

  const [inputData, setInputData] = useState(() => {
    const input = currentRemoteWorkflow?.meta?.settings?.input || [];
    return input.map((val) => {
      return `${val.variableName}`;
    });
  });


  // Effects:
  useEffect(() => {
    const {
      orderId = DEFAULT_OUTPUT_ORDERID,
      shopifyAction,
      email = DEFAULT_OUTPUT_EMAIL,
      address = DEFAULT_OUTPUT_ADDRESS,
      validateUserEmail = false,
      validateUserAddress = false,
    } = node;
    setOrderId(orderId);
    setShopifyAction(shopifyAction);
    setEmail(email);
    setAddress(address);
    setValidateUserEmail(validateUserEmail);
    setValidateUserAddress(validateUserAddress);
  }, [
    node.orderId,
    node.shopifyAction,
    node.email,
    node.address,
    node.validateUserEmail,
    node.validateUserAddress
  ]);
 
  useEffect(() => {
    if (node?.id && shopifyAction) {
      const updatedOutputFields = [
        ...outputFields,
        { id: node.id, values: [...(SHOPIFY_RESPONSE_FIELDS[shopifyAction] ?? [])] },
      ];

      const updatedDynamicFields = [
        ...(SHOPIFY_RESPONSE_FIELDS[shopifyAction] ?? []),
        ...dynamicFields,
      ];

      updateNode(node.id, {
        email,
        validateUserEmail,
        address,
        validateUserAddress,
        orderId,
        shopifyAction,
        outputFields: updatedOutputFields,
        dynamicFields: updatedDynamicFields,
      });
    }
  }, [
    node?.id,
    email,
    validateUserEmail,
    address,
    validateUserAddress,
    orderId,
    shopifyAction,
    outputFields,
    dynamicFields,
  ]);

  const handleSetShopifyAction = useCallback((e) => {
    setShopifyAction(e.target.value);
  }, []);


  // Return:
  return (
    <BaseConfiguration
      setDynamicFields={setDynamicFields}
      node={node}
      isEditable={isEditable}
      deleteNode={deleteNode}
      outputFields={outputFields}
      setOutputFields={setOutputFields}
      setNodes={setNodes}
    >
      <FieldName>Functionality</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="shopify-functionality-select"
          disabled={!isEditable}
          value={shopifyAction}
          onChange={handleSetShopifyAction}
        >
          {Object.entries(SHOPIFYFUNCTIONALITY).map(([key, value]) => (
            <StyledMenuItem key={key} value={key}>
              {value}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <AutocompleteGlobal Title={"Order ID"} dynamicFields={dynamicFields} data={orderId} DEFAULT_OUTPUT={DEFAULT_OUTPUT_ORDERID} setData={setOrderId} isEditable={isEditable} />
      <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
        <input
          type="checkbox"
          style={{ marginRight: "0.35rem" }}
          disabled={!isEditable}
          checked={validateUserEmail}
          onChange={() => setValidateUserEmail(!validateUserEmail)}
        />
        <div style={{ fontSize: "0.8rem" }}>Validate User Email</div>
      </div>
      {validateUserEmail && (<AutocompleteGlobal Title="EMAIL" dynamicFields={dynamicFields} data={email} DEFAULT_OUTPUT={DEFAULT_OUTPUT_EMAIL} setData={setEmail} isEditable={isEditable} />)}

      <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
        <input
          type="checkbox"
          style={{ marginRight: "0.35rem" }}
          disabled={!isEditable}
          checked={validateUserAddress}
          onChange={() => setValidateUserAddress(!validateUserAddress)}
        />
        <div style={{ fontSize: "0.8rem" }}>Validate User Address</div>
      </div>
      {validateUserAddress && (<AutocompleteGlobal Title="Address" dynamicFields={dynamicFields} data={address} DEFAULT_OUTPUT={DEFAULT_OUTPUT_ADDRESS} setData={setAddress} isEditable={isEditable} />)}
    </BaseConfiguration>
  );
};

// Exports:
export default memo(ShopifyConfiguration);