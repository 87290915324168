import { Wrapper, Table, Header, Body, Title, Filters } from "./styles";
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useTable,
    useSortBy,
    useFilters,
    usePagination
} from 'react-table';
import { useDeepCompareEffect } from 'react-use';
import { CSVLink } from 'react-csv';
import Pagination from "../../../components/Records/Pagination";
import { getChatActions } from "../../../redux/actions/chatActions";
import { ColumnFilter } from "./ColumnFilters";
import { Chip, CircularProgress, Button } from '@mui/material';
import { getContentActions } from '../../../redux/actions/contentActions';


import CallMissedOutlinedIcon from '@mui/icons-material/CallMissedOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { TableSortLabel } from '@mui/material';
import { GAPS_TOOLTIPS } from "../../../constants/tooltips";
import { REQUEST_GAP_TYPE } from "../../../constants/requestType";
import { filterGapsData } from "../../../utils/tableFilters";
import { sortingDateFunction } from "../../../utils/tableSort";
import gapsConfig from '../../../config/infov2/gaps_table.json'

const { headerMap } = gapsConfig;

const varPageSize = 10;
const initialState = {
    sortBy: [
        {
            id: 'Created',
            desc: true
        }
    ],
    pageSize: 10,
};

const GapsTable = ({
    startDate,
    endDate,
    dateRange,
    hideInfo,
    handleStat
}) => {
    const dispatch = useDispatch();
    const chatActions = getChatActions(dispatch);
    const contentActions = getContentActions(dispatch);
    const organisation_info = useSelector((state) => state.content.org_info);
    const orgid = organisation_info?.org_data?._id;
    const isLoading = useSelector((state) => state.content.loading);
    const reactions = useSelector((state) => state.content.reactionRecordsV2);
    const lastKey = useSelector((state) => state.content?.reactionRecordsLastKey);
    const [pageCount, setPageCount] = useState(Math.ceil(reactions.length / varPageSize) || 1);

    // State:
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        if (reactions?.length && !lastKey) return;
        contentActions.fetchReactionsV2(orgid, startDate, endDate, REQUEST_GAP_TYPE);
    }, [orgid]);

    const handleStats = (row) => {
        chatActions.fetchChatRecordByRecordId(orgid, row?.original?.recordId);
        setSelectedRow(row.id === selectedRow ? null : row.id);
        handleStat(row.original);
    }

    const { records, cols } = useMemo(() => {
        let records = [];
        let cols = [];
        if (reactions) {
            let mishit = reactions.filter(val => val.event_type === 'mishit')
            let dislike = reactions.filter(val => val.event_type === 'dislike')
            let escalation = reactions.filter(val => val.event_type === 'escalation')
            mishit = mishit.map(mis => (
                {
                    ...mis,
                    event_type: 'No Answer'
                }
            ));
            records = [...mishit, ...dislike, ...escalation];
            cols = ['event_type', 'created_date', 'query', 'recordId', 'gap_category'];
        }
        return {
            records,
            cols
        }
    }, [reactions])

    const [data, setData] = useState(records.slice(varPageSize));

    const columns = useMemo(() => {
        const tempArray = cols.map((col) => {
            const words = col.split('_');
            const capitalised = words.map(
                (word) => word.charAt(0).toUpperCase() + word.slice(1)
            );

            return {
                Header:
                    col in headerMap
                        ? headerMap[col]
                        : capitalised.join(' '),
                accessor: row => {
                    switch (col) {
                        case 'created_date':
                            return row.created_date.substring(0, 10) + " " + row.created_date.substring(11, 16);
                        case 'event_type':
                            return row.event_type === 'escalation' ? row?.escalation_type || row[col] : row[col];
                        default:
                            return row[col];
                    }
                },
                Filter: ColumnFilter,
                disableSortBy: (col === "created_date") ? false : true,
            };
        });
        return tempArray;
    }, [cols, hideInfo]);

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState,
            autoResetPage: false,
            autoResetFilters: false,
            manualPagination: true,
            manualSortBy: true,
            manualFilters: true,
            manualGlobalFilter: true,
            autoResetSortBy: false,
            autoResetExpanded: false,
            autoResetPage: false,
            pageCount: pageCount
        },
        useFilters,
        useSortBy,
        usePagination
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        gotoPage,
        prepareRow
    } = tableInstance;
    const { pageIndex, pageSize, sortBy, filters } = state;
    const onPageChange = useCallback((newVal, records) => {
        let data = records.slice(newVal * pageSize, (newVal + 1) * pageSize);
        const pageCount = Math.ceil(records.length / pageSize);
        setPageCount(pageCount || 1);
        if (newVal > pageCount - 2 && lastKey && !isLoading) {
            contentActions.fetchReactionsV2(orgid, startDate, endDate, REQUEST_GAP_TYPE);
        }
        return data;
    }, [pageIndex, lastKey, isLoading])

    useEffect(() => {
        if (reactions?.length) {
            let filterReactions = filterGapsData(reactions, filters);
            let sortedReactions = sortingDateFunction(filterReactions, sortBy) || [];
            let reaction = onPageChange(pageIndex, sortedReactions) || [];
            setData(reaction);
        }
    }, [sortBy, filters, reactions, pageIndex])

    useEffect(() => {
        gotoPage(0);
    }, [filters])

    return (
        <Wrapper>
            <Table {...getTableProps()}>
                {isLoading ?
                    (<thead style={{ marginTop: '15rem' }}>
                        <tr><th><CircularProgress /></th></tr>
                    </thead>) :
                    (<>
                        <Header>
                            {headerGroups.map((headerGroup) => (
                                <Title {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (

                                        <th {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}>
                                            {column.disableSortBy ? (
                                                <span {...column.getSortByToggleProps()} title={GAPS_TOOLTIPS[column.Header]}>
                                                    {column.render('Header')}
                                                </span>
                                            ) : (
                                                <TableSortLabel
                                                    active={column.isSorted}
                                                    direction={column.isSortedDesc ? 'desc' : 'asc'}
                                                    {...column.getSortByToggleProps()} title={GAPS_TOOLTIPS[column.Header]}
                                                >
                                                    {column.render('Header')}
                                                </TableSortLabel>
                                            )}
                                        </th>
                                    ))}
                                </Title>
                            ))}
                            {headerGroups.map((headerGroup) => (
                                <Filters {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            <div>
                                                {column.canFilter
                                                    ? column.render('Filter') : null
                                                }
                                            </div>
                                        </th>
                                    ))}
                                </Filters>
                            ))}
                        </Header>
                        {page && page.length > 0 ? (<>
                            <Body {...getTableBodyProps()}>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} onClick={() => handleStats(row)} style={{ backgroundColor: selectedRow === row.id ? 'lightblue' : '' }}>
                                            {row.cells.map((cell) => {
                                                if (cell.column.Header === 'Gap Type') {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {
                                                                <Chip
                                                                    label={row.original.event_type === "escalation" ? row?.original?.escalation_type || row.original.event_type : row.original.event_type}
                                                                    icon={<CallMissedOutlinedIcon />}
                                                                    color="warning"
                                                                    sx={{ color: '#D76A03', fontWeight: '600' }}
                                                                    size="small"
                                                                    variant="outlined"
                                                                />
                                                            }
                                                        </td>
                                                    );
                                                } else if (cell.column.Header === 'Created') {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {row.original.created_date.substring(0, 10) +
                                                                ' ' +
                                                                row.original.created_date.substring(11, 16)}
                                                        </td>
                                                    );
                                                } else {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    );
                                                }
                                            })}
                                        </tr>
                                    );
                                })}
                            </Body>
                        </>) : <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}><h2 style={{ textAlign: "center" }}>Sorry! No Content in the Given time range</h2></div>}

                    </>)}
            </Table>
            <Pagination
                gotoPage={gotoPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                previousPage={previousPage}
                nextPage={nextPage}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageCount={pageCount}
                isLastPage={!!(!(pageIndex < pageCount - 1) && !lastKey)}
        />

            {data !== null && (
                <CSVLink
                    filename={'gaps.csv'}
                    data={data.map((row) => {
                        let data = {
                            user_email: row.user_email,
                            type: row.type,
                            createdAt: row.createdAt,
                            dialog_id: row.dialog_id,
                            id: row.id,
                            orgid: row.orgid
                        };
                        return data;
                    })}
                >
                    <Button
                        variant="contained"
                        size="small"
                        startIcon={<FileUploadOutlinedIcon />}
                        sx={{ textTransform: 'none' }}
                    >
                        Export Data
                    </Button>
                </CSVLink>
            )}
        </Wrapper>
    );
}

export default GapsTable;