// Packages:
import React from 'react';
import Chip from '@mui/material/Chip';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import CallMissedOutlinedIcon from '@mui/icons-material/CallMissedOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';

import UserChart from '../UserChart'

// Imports:
import {
  Wrapper,
  Info,
  InfoTitle, 
  InfoWrapper,
  ReactionTable
} from './styles';
import { useSelector } from 'react-redux';

// Functions:
const TrackingInformation = (props) => {
  const chatRecordByRecordIds = useSelector(state => state?.chat?.chatRecordByRecordIds);
  const chatLoading = useSelector(state => state?.chat?.loading);
  const chaterror = useSelector(state => state?.chat?.error);
  const record_id = props.stat.recordId;

  // Return:
  return (
      <Wrapper>
        <Info>
          <InfoTitle>Event Review</InfoTitle>
          <InfoWrapper>
          <div>
              {props.stat && !chatLoading && !chaterror ? (
                <ReactionTable>
                  <tr>
                    <th>DTG:</th>
                    <td>
                    { 
                      props.stat.created_date
                    }
                    </td>
                  </tr>
                  <tr>
                    <th>End User:</th>
                    <td>
                    { props.hideInfo ? 
                          "••"+props.stat.user_email.slice(2, 5).trim()+"•••"+props.stat.user_email.slice(8, 11).trim()+"••" : 
                          props.stat.user_email 
                    }
                    </td>
                  </tr>
                  <tr>
                    <th>Event:</th>
                    <td>
                      {  props.stat.event_type === "like" ?
                            <Chip
                              label={props.stat.event_type}
                              icon={<ThumbUpOutlinedIcon />}
                              color="success"
                              sx={{ color: '#3F9820', fontWeight: '600' }}
                              size="small"
                              variant="outlined"
                            /> : 
                            props.stat.event_type === "dislike" ?
                              <Chip
                                label={props.stat.event_type}
                                icon={<ThumbDownOutlinedIcon />}
                                color="error"
                                sx={{ color: '#C71D1A', fontWeight: '600' }}
                                size="small"
                                variant="outlined"
                              /> :
                                <Chip
                                  label={ props.stat.event_type }
                                  icon={<CallMissedOutlinedIcon />}
                                  color="warning"
                                  sx={{ color: '#D76A03', fontWeight: '600' }}
                                  size="small"
                                  variant="outlined"
                                />
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Resolution:</th>
                    <td>
                      { 
                        chatRecordByRecordIds[record_id]?.conversation_type === 'AI' ?
                        <Chip label={chatRecordByRecordIds[record_id]?.conversation_type} icon={<SmartToyOutlinedIcon />} color="info" size="small" variant="outlined" sx={{ fontWeight:'600' }}/> :
                        
                        chatRecordByRecordIds[record_id]?.conversation_type === 'Leave Message' ?
                        <Chip label={chatRecordByRecordIds[record_id]?.conversation_type} icon={<ExitToAppOutlinedIcon />} color="error" size="small" variant="outlined" sx={{ fontWeight:'600' }}/> :
                        
                        chatRecordByRecordIds[record_id]?.conversation_type === 'Agent' ?
                        <Chip label={chatRecordByRecordIds[record_id]?.conversation_type} icon={<SupportAgentOutlinedIcon />} color="secondary" size="small" variant="outlined" sx={{ fontWeight:'600' }}/> :
                        
                        <Chip label={chatRecordByRecordIds[record_id]?.conversation_type} icon={<PersonOffOutlinedIcon />} color="warning" size="small" variant="outlined" sx={{ fontWeight:'600' }}/>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Sentiment:</th>
                    <td>
                      { 
                        chatRecordByRecordIds[record_id]?.analytics?.sentiment[0] === 'Positive' ?
                          <Chip label={chatRecordByRecordIds[record_id]?.analytics?.sentiment[0]} color="success" size="small" variant="outlined" sx={{ fontWeight:'600' }}/> :
                          
                          chatRecordByRecordIds[record_id]?.analytics?.sentiment[0] === 'Negative' ?
                          <Chip label={chatRecordByRecordIds[record_id]?.analytics?.sentiment[0]} color="error" size="small" variant="outlined" sx={{ fontWeight:'600' }}/> :
                          
                          chatRecordByRecordIds[record_id]?.analytics?.sentiment[0] === 'Neutral' ?
                          <Chip label={chatRecordByRecordIds[record_id]?.analytics?.sentiment[0]} color="info" size="small" variant="outlined" sx={{ fontWeight:'600' }}/> :
                          
                          <div style={{ color: 'grey' }}>N/A</div>
                      }
                    </td>
                  </tr>
                </ReactionTable>
              ) : (
                <div style={{ lineHeight: '1.5rem', padding: '0.25rem' }}>
                  Select a row to display reaction information
                </div>
              )}
            </div>
          </InfoWrapper>
        </Info>
      </Wrapper>
  );
};


// Exports:
export default TrackingInformation;
