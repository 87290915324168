// Packages:
import React, { memo, useEffect,useCallback, useState } from "react";
import {  MenuItem, FormControl, Select, styled, TextField, Autocomplete } from "@mui/material";

// Styles:
import { FieldName } from "../styles";
import BaseConfiguration from "../BaseConfiguration";
import { ACTION, DEFAULT_OUTPUT_ACCOUNTID, DEFAULT_OUTPUT_DATETIME, DEFAULT_OUTPUT_EMAIL, DEFAULT_OUTPUT_FIRSTNAME, DEFAULT_OUTPUT_LASTNAME, DEFAULT_OUTPUT_PHONENUMBER, DEFAULT_OUTPUT_TIMEZONE, DEFAULT_OUTPUT_USERID } from "./utils";
import AutocompleteGlobal from "../../Components/AutocompleteGlobal";

const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

// Functions:
const AcuityConfiguration = ({ node, updateNode, deleteNode, isEditable, currentRemoteWorkflow,setNodes }) => {

    const ACUITY_FIELDS = {
        'GET AVAILABILITY': ['dateTimeGrid'],
        'BOOK SLOT': ['slotBooked']
    };
  // State:
  const [action, setAction] = useState(node.action??"GET AVAILABILITY");
  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields, setOutputFields]=useState([])
  const [userId,setUserId]=useState(node.userId??DEFAULT_OUTPUT_USERID)
  const [phoneNumber,setPhoneNumber]=useState(node.phoneNumber??DEFAULT_OUTPUT_PHONENUMBER)
  const [timeZone, setTimeZone] = useState(node.timeZone ?? DEFAULT_OUTPUT_TIMEZONE);
  const [appointmentTypeID,setAppointmentTypeID]=useState(node.appointmentTypeID??DEFAULT_OUTPUT_ACCOUNTID)
  const [email,setEmail]=useState(node.email??DEFAULT_OUTPUT_EMAIL)
  const [firstName,setFirstName]=useState(node.firstName??DEFAULT_OUTPUT_FIRSTNAME)
  const [lastName,setLastName]=useState(node.lastName??DEFAULT_OUTPUT_LASTNAME)
  const [dateTime,setDateTime]=useState(node.dateTime??DEFAULT_OUTPUT_DATETIME)
  const [customFieldExpected,setCustomFieldExpected]=useState(false)
  const [numberOfCustomFields,setNumberOfCustomFields]=useState(0)
  const [customFieldMapping,setCustomFieldMapping]=useState(node.customFieldMapping??[])

  const [inputData, setInputData] = useState(() => {
    const input = currentRemoteWorkflow?.meta?.settings?.input || [];
    return input.map((val) => {
      return `${val.variableName}`;
    });
  });

  // Effects:
  useEffect(() => {
    setAction(node.action)
    setUserId(node.userId??DEFAULT_OUTPUT_USERID)
    setPhoneNumber(node.phoneNumber??DEFAULT_OUTPUT_PHONENUMBER)
    setTimeZone(node.timeZone??"")
    setAppointmentTypeID(node.appointmentTypeID??DEFAULT_OUTPUT_ACCOUNTID)
    setEmail(node.email??DEFAULT_OUTPUT_EMAIL)
    setFirstName(node.firstName??DEFAULT_OUTPUT_FIRSTNAME)
    setLastName(node.lastName??DEFAULT_OUTPUT_LASTNAME)
    setDateTime(node.dateTime??DEFAULT_OUTPUT_DATETIME)
    setCustomFieldExpected(node.customFieldExpected??false)
    setNumberOfCustomFields(node.numberOfCustomFields??0)
    setCustomFieldMapping(node.customFieldMapping??[])
  }, [node.action,node.userId,node.phoneNumber,node.timeZone,node.appointmentTypeID,node.email,node.firstName,node.lastName,node.dateTime,node.customFieldExpected,node.numberOfCustomFields,node.customFieldMapping]);
  
  useEffect(() => {
    updateNode(node.id, {  
        dynamicFields: [...(ACUITY_FIELDS[action] ?? []), ...dynamicFields],
        action,
        userId,
        phoneNumber,
        timeZone,
        appointmentTypeID,
        email,
        firstName,
        lastName,
        dateTime,
        customFieldExpected,
        numberOfCustomFields,
        customFieldMapping,
        outputFields: [...outputFields, { id: node?.id, values: [...(ACUITY_FIELDS[action] ?? [])] }],
    });
}, [dynamicFields, action, userId,phoneNumber, timeZone,appointmentTypeID,email,firstName,lastName,dateTime,customFieldExpected,numberOfCustomFields, outputFields,customFieldMapping]);

  const handleCheckboxChange = useCallback(() => {
    setCustomFieldExpected(!customFieldExpected);
    setNumberOfCustomFields(0);
    setCustomFieldMapping([]);
  }, [customFieldExpected]);

  const handleNumberOfInputChange = useCallback((e) => {
    const value = parseInt(e.target.value, 10) || 0;
    if (value >= 0) {
      setNumberOfCustomFields(value);
      setCustomFieldMapping((prevData) => {
        const newData = [...prevData];
        while (newData.length < value) {
          newData.push({ key:null, value:{fieldName:null,value:null} });
        }
        while (newData.length > value) {
          newData.pop();
        }
        return newData;
      });
    }
  }, []);

  const handleDataInputChange = useCallback(
    (index, field, value) => {
      const isDynamic = dynamicFields.includes(value);
      setCustomFieldMapping((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          [field]: field === 'value' ? (isDynamic ? { fieldName: value, value: null } : { fieldName: null, value: value }) : value
        };
        return newData;
      });
    },
    [setCustomFieldMapping, dynamicFields]
  );

  // Return:
  return (
    <BaseConfiguration
      setDynamicFields={setDynamicFields}
      node={node}
      isEditable={isEditable}
      deleteNode={deleteNode}
      outputFields={outputFields}
      setOutputFields={setOutputFields}
      setNodes={setNodes}
    >
      <FieldName>Functionality</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="acuity-functionality-select"
          disabled={!isEditable}
          value={action}
          onChange={(e)=>setAction(e.target.value)}
        >
          {Object.entries(ACTION).map(([key, value]) => (
             <StyledMenuItem key={key} value={value}>
              {value}
             </StyledMenuItem>
            ))}
        </Select>
      </StyledFormControl>
      <AutocompleteGlobal
           Title="User Id"
           dynamicFields={inputData}
           data={userId} 
           setData={setUserId}
           DEFAULT_OUTPUT={DEFAULT_OUTPUT_USERID}
           isEditable={isEditable}
      />
       <AutocompleteGlobal
           Title="Appointment Id Type"
           dynamicFields={inputData}
           data={appointmentTypeID} 
           setData={setAppointmentTypeID}
           DEFAULT_OUTPUT={DEFAULT_OUTPUT_ACCOUNTID}
           isEditable={isEditable}
      />
      <AutocompleteGlobal Title={"TIME ZONE"} dynamicFields={dynamicFields} data={timeZone} setData={setTimeZone} DEFAULT_OUTPUT={DEFAULT_OUTPUT_TIMEZONE} isEditable={isEditable} />
         {action!==ACTION.GET_AVAILABILITY && (
            <>
            <AutocompleteGlobal Title={"EMAIL"} dynamicFields={dynamicFields} data={email} setData={setEmail} DEFAULT_OUTPUT={DEFAULT_OUTPUT_EMAIL} isEditable={isEditable} />
            <AutocompleteGlobal Title={"First Name"} dynamicFields={dynamicFields} data={firstName} setData={setFirstName} DEFAULT_OUTPUT={DEFAULT_OUTPUT_FIRSTNAME} isEditable={isEditable} />
            <AutocompleteGlobal Title={"Last Name"} dynamicFields={dynamicFields} data={lastName} setData={setLastName} DEFAULT_OUTPUT={DEFAULT_OUTPUT_LASTNAME} isEditable={isEditable} />
            <AutocompleteGlobal Title={"Phone Number"} dynamicFields={dynamicFields} data={phoneNumber} setData={setPhoneNumber} DEFAULT_OUTPUT={DEFAULT_OUTPUT_PHONENUMBER} isEditable={isEditable} />
            <AutocompleteGlobal Title={"Date Time"} dynamicFields={dynamicFields} data={dateTime} setData={setDateTime} DEFAULT_OUTPUT={DEFAULT_OUTPUT_DATETIME} isEditable={isEditable} />
            </>
         )} 
  <FieldName>Custom Fields</FieldName>  
          <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
      >
        <input
          type="checkbox"
          style={{ marginRight: "0.35rem" }}
          disabled={!isEditable}
          checked={customFieldExpected}
          onChange={handleCheckboxChange}
        />
        <div style={{ fontSize: "0.8rem" }}>custom Field expected:</div>
        <input
          type="number"
          min="0"
          disabled={!customFieldExpected || !isEditable}
          style={{
            width: "3rem",
            marginLeft: "0.25rem",
          }}
          value={numberOfCustomFields}
          onChange={handleNumberOfInputChange}
        />
      </div>
      {customFieldExpected && (
  <div style={{ display: "flex", flexDirection: "column" }}>
    {customFieldMapping.map((mapping, index) => (
      <div key={index} style={{ display: "flex", flexDirection: "row" }}>
        <StyledTextField
          type="text"
          placeholder="Key"
          disabled={!isEditable}
          value={mapping?.key ?? ''}
          size="small"
          style={{ padding: 0 }}
          onChange={(e) =>
            handleDataInputChange(index, 'key', e.target.value)
          }
        />
        <Autocomplete
        options={dynamicFields}
        disabled={!isEditable}
        value={mapping?.value["fieldName"]??mapping?.value["value"]}
        fullWidth
        freeSolo
        onChange={(_,value)=>handleDataInputChange(index, 'value', value)}
        onInputChange={(_,value)=>handleDataInputChange(index, 'value', value)}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            placeholder="Select or Enter Value"
            variant="outlined"
            size="small"
            style={{ padding: 0 }}
          />
        )}
      />
      </div>
    ))}
  </div>
)}  
    </BaseConfiguration>
  );
};

// Exports:
export default memo(AcuityConfiguration);
