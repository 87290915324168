// Packages:
import React, { memo, useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  FormControl,
  Select,
  styled,
} from "@mui/material";
import { MdCancel } from "react-icons/md";

// Styles:
import { FieldName, VariableField, Variable } from "../styles";
import { CARRIER, DEFAULT_OUTPUT, DEFAULT_OUTPUT_APIKEY, DEFAULT_OUTPUT_APISECRET, DEFAULT_OUTPUT_EMAIL, SHIPPINGFUNCTIONALITY, SHIPPINGTYPE, SHIPPING_RESPONSE_FIELDS, changeName } from "./utils";
import AutocompleteGlobal from "../../Components/AutocompleteGlobal";
import BaseConfiguration from "../BaseConfiguration";

const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

// Functions:
const ShippingConfiguration = ({
  node,
  updateNode,
  deleteNode,
  isEditable,
  currentRemoteWorkflow,
  setNodes
}) => {
  // Constants:
  const [inputData, setInputData] = useState(() => {
    const input = currentRemoteWorkflow?.meta?.settings?.input || [];
    return input.map((val) => {
      return `${val.variableName}`;
    });
  });

  // State:
  const [carrier, setCarrier] = useState(node.carrier);
  const [shippingEasyAction, setShippingEasyAction] = useState(
    node.shippingEasyAction
  );
  const [shippingType, setShippingType] = useState(node.shippingType);
  const [order_id, setOrderId] = useState(node.order_id ??DEFAULT_OUTPUT);
  const [apiKey,setApiKey] =useState(node.apiKey??DEFAULT_OUTPUT_APIKEY)
  const [apiSecret,setApiSecret]=useState(node.apiSecret??DEFAULT_OUTPUT_APISECRET)
  const [email,setEmail]=useState(node.email??DEFAULT_OUTPUT_EMAIL)
  const [validateUserEmail,setValidateUserEmail]=useState(node.validateUserEmail??false)

  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields,setOutputFields]=useState([])
 

  // Effects:
  useEffect(() => {
    setCarrier(node.carrier);
    setShippingEasyAction(node.shippingEasyAction);
    setShippingType(node.shippingType);
    setApiKey(node.apiKey??DEFAULT_OUTPUT_APIKEY)
    setApiSecret(node.apiSecret??DEFAULT_OUTPUT_APISECRET)
    setOrderId(node.order_id??DEFAULT_OUTPUT)
    setEmail(node.email??DEFAULT_OUTPUT_EMAIL)
    setValidateUserEmail(node.validateUserEmail??false)
  }, [node.carrier, node.shippingEasyAction, node.shippingType,node.apiKey,node.apiKey,node.apiSecret,node.apiSecret,node.order_id,node.order_id,node.email,node.validateUserEmail]);

  useEffect(() => {
    updateNode(node.id, {
      carrier,
      shippingEasyAction,
      shippingType,
      apiKey,
      apiSecret,
      order_id,
      email,
      validateUserEmail,
      dynamicFields:
       [...(shippingEasyAction ===changeName(SHIPPINGFUNCTIONALITY.FETCH_ORDER_DETAILS)
          ? SHIPPING_RESPONSE_FIELDS[carrier]
          : []),...dynamicFields],
     outputFields: [...outputFields, {id: node?.id,values: [...(shippingEasyAction ===changeName(SHIPPINGFUNCTIONALITY.FETCH_ORDER_DETAILS)
      ? SHIPPING_RESPONSE_FIELDS[carrier]
      : [])]}], 
    
    });
  }, [carrier, shippingEasyAction, shippingType, apiKey, apiSecret,email, order_id,dynamicFields,validateUserEmail]);


  // Return:
  return (
    <BaseConfiguration  setDynamicFields={setDynamicFields} node={node} isEditable={isEditable} deleteNode={deleteNode} outputFields={outputFields} setOutputFields={setOutputFields} setNodes={setNodes}>
      <FieldName>Carrier</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="shipping-carrier-select"
          value={carrier}
          disabled={!isEditable}
          onChange={(e) => setCarrier(e.target.value)}
        >
          {Object.values(CARRIER).map((functionality, index) => (
            <StyledMenuItem key={index} value={functionality}>
              {functionality}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>

      <FieldName>Functionality</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="shipping-functionality-select"
          disabled={!isEditable}
          value={changeName(shippingEasyAction)}
          onChange={(e) => setShippingEasyAction(changeName(e.target.value))}
        >
          {Object.values(SHIPPINGFUNCTIONALITY).map((functionality, index) => (
            <StyledMenuItem key={index} value={functionality}>
              {functionality}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <AutocompleteGlobal Title="API KEY" dynamicFields={inputData} data={apiKey} setData={setApiKey} DEFAULT_OUTPUT={DEFAULT_OUTPUT_APIKEY} isEditable={isEditable} />
      <AutocompleteGlobal Title="API SECRET" dynamicFields={inputData} data={apiSecret} setData={setApiSecret} DEFAULT_OUTPUT={DEFAULT_OUTPUT_APISECRET} isEditable={isEditable} />

      {changeName(shippingEasyAction) === "CHANGE SHIPPING TYPE" ? (
        <>
          <FieldName>Type</FieldName>
          <StyledFormControl size="small" fullWidth>
            <Select
              id="shipping-type-select"
              disabled={!isEditable}
              value={shippingType}
              onChange={(e) => setShippingType(e.target.value)}
            >
              {Object.values(SHIPPINGTYPE).map((functionality, index) => (
                <StyledMenuItem key={index} value={functionality}>
                  {functionality}
                </StyledMenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </>
      ) : (
        <></>
      )}

{changeName(shippingEasyAction) === "FETCH ORDER DETAILS" && (
  <>
    <AutocompleteGlobal Title="Order ID" dynamicFields={dynamicFields} data={order_id} DEFAULT_OUTPUT={DEFAULT_OUTPUT} setData={setOrderId} isEditable={isEditable}/>
    <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
      <input
        type="checkbox"
        style={{ marginRight: "0.35rem" }}
        disabled={!isEditable}
        checked={validateUserEmail}
        onChange={() => setValidateUserEmail(!validateUserEmail)}
      />
      <div style={{ fontSize: "0.8rem" }}>Validate User Email</div>
    </div>
    {validateUserEmail && ( <AutocompleteGlobal Title="EMAIL" dynamicFields={dynamicFields} data={email} DEFAULT_OUTPUT={DEFAULT_OUTPUT_EMAIL} setData={setEmail} isEditable={isEditable}/>)}
  </>
)}

      </BaseConfiguration>
  );
};

// Exports:
export default  memo(ShippingConfiguration);