import sessionConfig from '../config/infov2/session_table.json';
import eventsConfig from '../config/infov2/events_table.json';
import gapsConfig from '../config/infov2/gaps_table.json';
import { EVENT_TYPES } from '../constants/events';

const { headerToPropSession } = sessionConfig;
const { headerTopropEvents } = eventsConfig;
const { headerToPropGaps } = gapsConfig;

export const filteredChatData = (records, filters) => {
    if(!filters?.length) return records;
    return records.filter(record => {
        for(const filter of filters){
            let flag = true;
            const {id, value} = filter;
            const headerProp = headerToPropSession[id];
            if(!headerProp) continue;

            switch (headerProp) {
                case "user_personas":
                    flag = record[headerProp]?.some(rec => rec.toLowerCase().includes(value?.toLowerCase()));
                    break;
                case "tags":
                    flag = record[headerProp]?.some(rec => rec?.value?.toLowerCase().includes(value?.toLowerCase()));
                    break;
                case "intent":
                    flag = record?.analytics?.[headerProp]?.[0]?.toLowerCase()?.includes(value?.toLowerCase());
                    break;
                case "sentiment":
                    flag = value?.some(val => record?.analytics?.[headerProp]?.[0].toLowerCase().includes(val.toLowerCase()));
                    break;
                case "conversation_type":
                    flag = value?.some(val => record[headerProp]?.toLowerCase().includes(val.toLowerCase()));
                    break;
                default:
                    flag = typeof record[headerProp] === "string" && record[headerProp]?.toLowerCase()?.includes(value?.toLowerCase());
                    break;
            }
            if(!flag) return false;
        }
        return true;
    });
}

export const filterEventsData = (events, filters) => {
    return events.filter(evt => {
        let flag = true;
        for(const filter of filters){
            const {id, value} = filter;
            const headerProp = headerTopropEvents[id];
            if(headerProp === "event_type"){
                flag &&= ( value?.some(val => evt[headerProp]?.toLowerCase().includes(val.toLowerCase())) );
            }
            else{
                flag &&= ( evt[headerProp]?.toLowerCase() )?.includes(value?.toLowerCase());
            }
        }
        return flag;
    })
}

export const filterGapsData = (events, filters) => {
    return events.filter(evt => {
        for(const filter of filters){
            let flag = true;
            const {id, value} = filter;
            const headerProp = headerToPropGaps[id];
            if(!headerProp) continue;

            if(headerProp === "gap_category"){
                flag = ( evt[headerProp]?.[0]?.toLowerCase() )?.includes(value?.toLowerCase());
            }
            else if(evt[headerProp] === EVENT_TYPES.ESCALATION_EVENT){
                flag = ( value?.some(val => evt["escalation_type"]?.toLowerCase().includes(val.toLowerCase())) );
            }
            else if(headerProp === "event_type"){
                flag = ( value?.some(val => evt[headerProp]?.toLowerCase().includes(val.toLowerCase())) );
            }
            else{
                flag = ( evt[headerProp]?.toLowerCase() )?.includes(value?.toLowerCase());
            }
            if(!flag) return false;
        }
        return true;
    })
}

export const globalFilterChatData = (records, filter) => {
    if(!filter) return records;
    let lowerCaseValue = filter?.toLowerCase();    
    return records.filter(record => {
        for(let key in record){
            if(key === "feedback" || key === "orgId"){
                continue;
            }
            let flag = false;
            if(key === "user_personas"){
                flag = record[key]?.some(rec => ( rec.toLowerCase() )?.includes(lowerCaseValue));
            }
            else if (key === "tags"){
                flag = record[key]?.some(rec => rec?.value?.toLowerCase()?.includes(lowerCaseValue))
            }
            else if(key === "analytics"){
                flag = flag || ( record?.analytics?.["sentiment"]?.[0]?.toLowerCase() )?.includes(lowerCaseValue);
                flag = flag || ( record?.analytics?.["intent"]?.[0]?.toLowerCase() )?.includes(lowerCaseValue);
            }
            else if(typeof record[key] === "string"){
                flag = ( record[key]?.toLowerCase() )?.includes(lowerCaseValue);
            }
            if(flag) return true;
        }
        return false;
    })
}