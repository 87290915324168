import { toast } from "react-toastify";
import { VALID_URL_REGEX } from "../../constants/regex";
import { SUPERVISOR, SUPER_ADMIN } from "../../constants/user";
import { deleteDraft } from "./draftsOperations";
import {
  WORKFLOW_IS_DRAFT,
  WORKFLOW_IS_PUBLISHED,
  WORKFLOW_IS_UNDER_REVIEW,
} from "../../constants/studioV2";
import {
  getWorkflow,
  getWorkflowContent,
} from "../../pages/StudioV2/utils/api";
import { archiveWorkflow } from "./publishedOperations";

const isAdmin = (agentType) =>
  agentType === SUPERVISOR || agentType === SUPER_ADMIN;
export async function loadWorkflowContent(workflow) {
  const response = await getWorkflowContent(
    workflow.orgId,
    workflow.workflowId,
    workflow.meta.owner,
    workflow.meta.status,
    workflow.meta.version
  );
  return response?.data?.workflowData;
}

export async function loadWorkflowData(
  orgId,
  workflowId,
  owner,
  status,
) {
  const response = await getWorkflow(orgId, workflowId, owner, status);
  return response?.data?.workflow;
}

export async function isWorkflowValid(workflow) {
  if (!workflow?.workflowId || !workflow?.orgId || !workflow?.meta) {
    toast.error("Missing or malformed workflow data");
    return false;
  }
  if (workflow.meta.workflowName?.length < 1) {
    toast.error("Title cannot be empty");
    return false;
  }
  if (!workflow.meta.target) {
    toast.error("Please select the target user for this workflow");
    return false;
  }
  if (!workflow.meta.access) {
    toast.error("Please select the access level for this workflow");
    return false;
  }
  if (
    workflow.meta.s3Link?.length > 0 &&
    !VALID_URL_REGEX.test(workflow.meta.s3Link ?? "")
  ) {
    toast.error("Source should be a valid URL or empty");
    return false;
  }
  return true;
}

export async function deleteWorkflow(workflow, agentEmail, agentType) {
  if (!workflow?.workflowId || !workflow?.orgId || !workflow?.meta) {
    toast.error("Missing or malformed workflow data!");
    return;
  }
  if (!agentEmail || !agentType) {
    toast.error("You need to be logged in to delete articles");
    return;
  }

  switch(workflow.meta.status) {
    case WORKFLOW_IS_PUBLISHED:
      if (!isAdmin(agentType)) {
        toast.error('Only admins can delete published articles!');
        return;
      }
      if (workflow.meta.isArchived) {
        toast.info('This workflow is already archived!');
        return;
      }
      await archiveWorkflow(workflow)
      return;
    case WORKFLOW_IS_DRAFT:
      if (workflow.meta.owner !== agentEmail) {
        toast.error('Drafts can only be deleted by their owner!');
        return;
      }
      await deleteDraft(workflow)
      return;
    case WORKFLOW_IS_UNDER_REVIEW:
      if (workflow.meta.owner !== agentEmail && !isAdmin(agentType)) {
        toast.error('Submitted drafts can only be deleted by their owner or admins.');
        return;
      }
      await deleteDraft(workflow);
      return;
    default:
      toast.error('Missing or malformed article data!');
      return;
  }
}